import { Button, Card, Image, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import about from '../assets/about.jpg'
import aboutWide from '../assets/about-wide.jpg'

import aboutBanner1 from '../assets/about-banner1.jpg'
import aboutBanner2 from '../assets/about-banner2.jpg'
import aboutBanner3 from '../assets/about-banner3.jpg'
import aboutBanner4 from '../assets/about-banner4.jpg'
import aboutMatt from '../assets/about-matt.jpg'
import aboutElliot from '../assets/about-elliot.jpg'

export default function About() {
  const navigate = useNavigate()

  return (
    <>
      <Row className="redup-red-background">
        <Col xl={{offset: 1}}><h1><strong>Meet the founder.</strong></h1></Col>
      </Row>
      <Row>
        <Col xs={{ span: 10, offset: 1 }} className="d-flex d-md-none align-items-center">
          <Image fluid src={aboutWide} alt="Founder George Georgiadis" />
        </Col>
        <Col md="8" xl={{ span: 6, offset: 1 }} style={{paddingTop: "12px"}}>
          <p>RedUp was founded by George Georgiadis. With over 20 years of professional experience in both medical & commercial storage and moving industries, he is excited to bring a more personalized storage experience to communities in Ohio.</p>
          <p>George was born and raised in Northeast Ohio. His parents were both first generation Greek Americans whose families immigrated to the United States in the 1920s. His father and uncle, James & Jack Georgiadis, owned Jimbo’s Diner where he started working as a young boy. Through his family he grew up learning the importance of faith, hard work, and service.</p>
          <p>As an adult George has followed the example set for him. He continues to dedicate his life to his family and community. For nearly 20 years he ran his own medical & commercial storage business, using it as a foundation to support families, schools, and other organizations locally. He feels fortunate to be able to able to continue to provide professional services through RedUp, and looks forward to the ways in which it can make a positive impact in Northeast Ohio.</p>
        </Col>
        <Col md="4" className="d-flex d-none d-md-block">
          <Image fluid src={about} style={{position: "relative", top: "-84px", marginBottom: "-48px"}} alt="Founder George Georgiadis" />
        </Col>
      </Row>
      <Row style={{marginTop: "12px"}}>
        <Col className="d-flex align-items-center">
          <p className=" text-center w-100">
            <Image height="150" src={aboutBanner1} alt="George Georgiadis Family 1" />
            <Image height="150" src={aboutBanner2} alt="George Georgiadis Family 2" />
            <Image height="150" src={aboutBanner3} alt="George Georgiadis Family 3" />
            <Image height="150" src={aboutBanner4} alt="George Georgiadis Family 4" />
          </p>
        </Col>
      </Row>
      <Row>
        <Col><hr /></Col>
      </Row>
      <Row>
        <Col xs="4" sm="3" md="3" xl={{ span: 2, offset: 1 }} className="align-items-center">
          <Image height="200" src={aboutMatt} alt="Matt DePero headshot" />
        </Col>
        <Col xs="8" sm="9" md="3" xl="3" className="align-items-center">
          <h2>Chief Technology Officer</h2>
          <p><strong>Matthew DePero</strong></p>
          <p style={{marginTop:"8px"}}>
            A graduate of Miami University with a masters in Computer Science, Matt has worked in tech for several years in Silicon Valley and now lives in San Francisco. The RedUp team is thrilled to have him on board and values the skills and expertise he brings to the organization.
          </p>
        </Col>
        <Col xs="4" sm="3" md="3" xl="2" className="align-items-center">
          <Image height="200" src={aboutElliot} alt="Elliot Georgiadis headshot" />
        </Col>
        <Col xs="8" sm="9" md="3" xl="3" className="align-items-center">
          <h2>Communications Director</h2>
          <p><strong>Elliot Georgiadis</strong></p>
          <p style={{marginTop:"8px"}}>
           Elliot is excited to bring apply knowledge and background experiences to the RedUp team and help grow the company in Northeast Ohio. She earned a Master’s in Communication from the University of Akron looks forward to serving others through the organization. 
          </p>
        </Col>
      </Row>
      <Row className="redup-red-background">
        <Col><h1 className="text-center">Why on-demand storage?</h1></Col>
      </Row>
      <Row style={{marginTop: "12px"}}>
        <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <p>While ‘self-storage’ should be an easy to use service, it isn’t always the case. When talking about self-storage to others, whether homeowners, renters, families, or young adults . . . George always found similar issues to his own with using a storage facility.</p>
          <p className="text-center fw-bold"><em>“Why are storage units SO expensive?”</em></p>
          <p className="text-center fw-bold"><em>“What about all the space I am wasting? We don’t really need that much room, but this size is all the facility has available.”</em></p>
          <p className="text-center fw-bold"><em>“Getting in and out of the unit is always such a hassle. I wish it was more convenient.”</em></p>
          <p>Looking to address the holes within the industry, George has spent years extensively studying self-storage and consulting with business & technology experts to develop a more user-friendly approach.</p>
          <p>RedUp offers a more convenient and less expensive storage solution to customers. By selecting the number of bins you want, you only ever have to pay for the EXACT amount of storage you need. With RedUp’s pick-up and delivery services, customers get the added benefit of clearing up space without ever leaving home.</p>
          <p>The team at RedUp is thrilled to be able to bring a modern and convenient storage option to customers and looks forward to serving them.</p>
        </Col>
      </Row>
    </>
  )
}
