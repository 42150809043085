import { Button, Card, Image, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Success() {
  const navigate = useNavigate()
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
  const emailAddress = process.env.REACT_APP_EMAIL

  return (
    <>
      <Row style={{marginTop: "48px"}} className="text-center">
        <Col>
          <h1>THANK YOU FOR YOUR ORDER!</h1>
          <p>Your order was completed successfully, we appreciate your business! You should receive a confirmation email of your order shortly. Should you have any questions or need to make changes, please reach out and a member of our team will be in touch.</p>
        </Col>
      </Row>
    </>
  )
}
