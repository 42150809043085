import { Button, Card, Image, Col, Row, Nav } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import welcome from '../assets/welcome.jpg'
import works1 from '../assets/works1.png'
import works2 from '../assets/works2.png'
import works3 from '../assets/works3.png'
import works4 from '../assets/works4.png'

export default function Welcome() {
  const navigate = useNavigate()

  return (
    <>
      <Row className="redup-red-background">
        <Col md="6"></Col>
        <Col md="6"><h1><strong>ON-DEMAND STORAGE</strong></h1></Col>
      </Row>
      <Row>
        <Col md={{span: "5", offset: "1"}} className="d-none d-md-block">
          <Image fluid src={welcome} style={{position: "relative", top: "-84px", marginBottom: "-48px"}} alt="welcome to redup" />
        </Col>
        <Col xs="12" className="d-block d-md-none">
          <Image fluid src={welcome} alt="welcome to redup" />
        </Col>
        <Col md="6">
          <div>
            <h3 class="redup-red-text" style={{marginTop: "24px"}}><em><strong>STORE YOUR STUFF WITHOUT EVER LEAVING YOUR HOME</strong></em></h3>
            <p style={{marginTop: "12px"}}>RedUp makes the storage process easy by doing the work for you. We pick up whatever you want stored, keep it safe, & bring it back whenever you would like.</p>
          </div>
        </Col>
      </Row>
      <div className="redup-red-background">
      <Row style={{paddingTop: "24px"}}><Col xs="12"><h2 className="text-center">HERE IS HOW IT WORKS . . .</h2></Col></Row>
      <Row>
        <Col md="3" className="text-center">
          <Image height="120" style={{marginLeft: "auto"}} src={works1} alt="bin selection icon" />
          <h3>Bin Selection</h3>
          <p>Decide how many bins you would like, and we will drop them off within 24 hours.</p>
        </Col>
        <Col md="3" className="text-center">
          <Image height="120" style={{marginLeft: "auto"}} src={works2} alt="fill it icon" />
          <h3>Fill It</h3>
          <p>Pack your bins with whatever you want to send to storage.</p>
        </Col>
        <Col md="3" className="text-center">
          <Image height="120" style={{marginLeft: "auto"}} src={works3} alt="pick up icon" />
          <h3>Pick Up</h3>
          <p>When you are ready, we will come pick the bins up and take them away!</p>
        </Col>
        <Col md="3" className="text-center">
          <Image height="120" style={{marginLeft: "auto"}} src={works4} alt="delivery icon" />
          <h3>Delivery</h3>
          <p>Need your things back? Let us know and we will drop them off during your selected delivery time.</p>
        </Col>
      </Row>
      </div>
      <Row style={{marginTop: "24px"}}>
        <Col>
          <h2 className="text-center">Ready to get started?</h2>
          <p className="text-center">Press the button below to enter your information and request the number of new bins you would like!</p>
          <p className="text-center">
            <LinkContainer to="/cart"><Nav.Link>
              <Button variant="danger" size="lg" style={{backgroundColor: "#DB0E24"}}>Get Pricing</Button>
            </Nav.Link></LinkContainer>
          </p>
        </Col>
      </Row>
    </>
  )
}
