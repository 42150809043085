import { Button, Card, Image, Accordion, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Rules() {

  return (
    <>
      <Row style={{marginTop: "48px"}}>
        <Col lg={{span: 10, offset: 1}} xl={{span:8, offset: 2}}>

<div>

<h3><strong>Rules of Storage</strong></h3>
<p><strong>Prohibited Items</strong></p>
<p>The top priority of RUS is the safety of our customers, their belongings, and the safety of our team members and associates. The items listed below are considered prohibited and are banned from storage with RUS. Such “Prohibited Items” include . . .&nbsp;</p><ul data-rte-list="default"><li>
<p>Any kind of firearm, or anything used primarily as a weapon.&nbsp;</p></li><li>
<p>Any kind of explosive or combustible device, including but not limited to fireworks, explosives, ammunition, and other flammables like oil, gas, lighter fluid, paint, or kerosene.&nbsp;</p></li><li>
<p>Anything that is illegal, such as drugs or drug paraphernalia, stolen property, or any such thing the user could be arrested for possessing</p></li><li>
<p>Anything that is alive or was alive, including such things as fruit, meats, dairy products, animals, insects, house plants, fungus, bacterial cultures, etc.&nbsp;</p></li><li>
<p>Any product that is edible or was edible. This includes all perishable and nonperishable food items, whether or not they are in a sealed container.&nbsp;</p></li><li>
<p>Any item that smells or has smoke damage, such as items that may attract animals or insects or are hazardous and/or toxic and may produce odors or gasses that could create pressure, increase in size, or burst.</p></li><li>
<p>Anything that has some form of liquid in it or any substance that could leak or spread, such as paint cans, cleaning sprays, or hair care bottled products.&nbsp;</p></li><li>
<p>Any item that has not been adequately prepared or packaged for storage with an RUS provided storage bin.&nbsp;</p></li></ul>
<p>Should a user fail to comply with the Terms and this policy and store any such Prohibited Item, users may be subject to additional penalties and fees, or even the termination of their account and Services with RUS. If RUS finds Prohibited Items in the user’s belongings, such items may be subject to quarantine and RUS reserves the right, at our sole discretion, to return or dispose of said items at the cost of the user.&nbsp;Should users have questions about the Rules of Storage, please contact an RUS team member.&nbsp;</p>
<p><strong>Fragile Items&nbsp;</strong></p>
<p>RUS does not offer additional protection for items that are considered fragile. Under the protection and limited liability policies of RUS, as outlined in the Terms, we cover $0.60 per pound for item damage. RUS has no liability for other specified types of damage that occurs and is described in this policy.&nbsp;</p>
<p>RUS asks that users avoid storing items that could be considered fragile (“Fragile Items”). Though policies are in place that cover the storage of such items, the outline included is not a complete description or list of all kinds of Fragile Items. As stated in the Terms, under no circumstance will RUS be liable for any damages in an excess of the amount specified. RUS does not have any liability for certain damages, defects, or other claims related to special, valuable, and fragile items as defined in these Rules of Storage.</p>
<p>Should a user voluntarily choose to store Fragile Items with RUS, it is the users responsibility to pack those items securely so no damage occurs during transportation or while the item is in storage. RUS encourages the user to take precautions and pack their bins with appropriate packing materials such as padding or bubble wrap. RUS reserves the right to require the purchase of appropriate packing supplies by the user or to not accept any bins or items that do not appear to be securely packed.&nbsp;</p>
<p>Fragile Items are common in homes and may include, without limitation, any kind of dishes, pottery, glassware, crystal, ornaments, ceramics, or any other kind of decorative or non-decorative item that is susceptible to cracking, being scratched, chipping, breaking, or shattering. Additionally, RUS considers all electronics to be Fragile Items which includes, but is not limited to, phones, printers, stereos, tablets, hard drives, or computers. RUS also acknowledges the internal mechanisms of Fragile Items, such as small appliances, electronics, and musical instruments. As RUS is not able to verify the function of such internal processes of various items, RUS is not in any way liable for electrical or mechanical failures or any kind of internal damage. This includes any Fragile Items whose internal processes and elements are susceptible to corrosion or any kind of effect from temperature or air.&nbsp;</p>
<p>By accepting the Agreement and using RUS Services, users agree to the conditions of these Rules of Storage and the Terms that limit the liability of RUS for Fragile Items that users choose to store.&nbsp;</p>
<p><strong>Valuable Documents</strong></p>
<p>RUS recommends users do not store any kind of “valuable documents” with us. This includes, but is not limited to bonds, deeds, stock certificates, currency, or securities. This also includes, but is not limited to, any kind of personal document such as birth certificates, marriage certificates, passports, driver’s licenses, visas, or green cards, or anything that contains information in which the user could be personally identified such as a social security number, bank account number, mortgage, or date of birth. This is not a complete description or list of Valuable Documents, and users are responsible for not storing anything of significant value that could be used to steal identity or that the user would need access to in a short amount of time.&nbsp;</p>
<p>By accepting this Agreement and using RUS Services, users understand and agree that should they choose to store any kind of Valuable Document, they waive all rights and claims towards RUS, to the fullest extent permitted by law, that occur in any manner during the storage of such items with RUS and use of RUS Services.&nbsp;</p>
<p><strong>Digital Documents</strong></p>
<p>RUS recommends that users have copies of any digital documents or files before placing them in storage with RUS that they keep. Such items include but are not limited to DVDs, hard drives, memory sticks, or any other form of digital media or digital storage. RUS is in no way responsible for, under any circumstance, the damage, corruption, or loss of such items.</p>
<p><strong>Other Items of Significant Value</strong></p>
<p>RUS recommends users do not store any kind of item of “Significant Value”. Such item that if needed to be replaced was valued at an amount over $100.00 per pound is considered to be of Significant Value. Examples include but are not limited too jewelry, furs, digital files, historical artifacts, fine art, antiques, autographed or special edition books or specialty items or collectables, sentimental items, musical instruments, or any kind of unique or rare item that could not be replaced if damaged or whose cost were to be higher than the cost of a new item in similar condition or meaning. Should the user choose to store such items with RUS, we recommend the use of a third party insurer to cover the value of the items placed into storage. The liability of RUS for such items is limited by our protection and limited liability policies and other policies described in the Terms. RUS reserves the right to assess such items and decline to store any such item deemed by us to be of Significant Value at our sole discretion.</p>


</div>

        </Col>
      </Row>
    </>
  )
}
