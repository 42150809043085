import React, { useState, useEffect } from 'react'
import { Card, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase.js'
import { ref, onValue, get } from 'firebase/database'

export default function Account() {
  const navigate = useNavigate()
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
  const emailAddress = process.env.REACT_APP_EMAIL
  const { currentUser } = useAuth()

  /*
  const [userBinRequests, setUserBinRequests] = useState([])
  
  useEffect(() => {
    return onValue(ref(db, `users/${currentUser.uid}/bin_requests`), (snapshot) => {
      if (!snapshot.exists()) {
        console.log("no bin request data found, this shouldn't happen...'");
        return;
      }
      console.log("got value: "+snapshot.val())
      setUserBinRequests(snapshot.val() ? snapshot.val() : [])
    }, (error) => {
        alert("error in onvalue: "+JSON.stringify(error))
    });
  }, [userBinRequests])
  */

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Account</h2>
          <p><strong>Name:</strong> {currentUser.displayName}</p>
          <p><strong>Email:</strong> {currentUser.email}</p>
          <p>To update your account details, such as contact information or address, please reach out to {emailAddress} or call {phoneNumber}.</p>
        </Card.Body>
      </Card>
    </>
  )
}
