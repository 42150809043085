import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider, AdminRoutes, AuthRoutes, PublicRoutes } from './contexts/AuthContext'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import Admin from './components/Admin'
import Signup from './components/Signup'
import Login from './components/Login'
import Account from './components/Account'
import Dashboard from './components/Dashboard'
import Welcome from './components/Welcome'
import About from './components/About'
import Contact from './components/Contact'
import FAQs from './components/Faqs'
import Request from './components/Request'
import Return from './components/Return'
import Cart from './components/Cart'
import Success from './components/Success'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import Rules from './components/Rules'
import Pricing from './components/Pricing'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/app.scss';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <AuthProvider>
      <Routes>
      <Route path="/" element={<App />}>
        <Route element={<PublicRoutes to="dashboard" />}>
          <Route index element={<Welcome />} />
          <Route path="login" element={<Login />} />
        </Route>
        <Route element={<AuthRoutes to="/" />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="account" element={<Account />} />
        </Route>
        <Route element={<AdminRoutes to="/" />}>
          <Route path="admin" element={<Admin />} />
        </Route>
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="request" element={<Request />} />
        <Route path="return" element={<Return />} />
        <Route path="faq" element={<FAQs />} />
        <Route path="cart" element={<Cart />} />
        <Route path="success" element={<Success />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="rules" element={<Rules />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="*" element={<div>404 >_></div>} />
      </Route>
      </Routes>
    </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
