import { Button, Card, Image, Col, Row, Nav } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import bin from '../assets/bin.jpg'
import binExample1 from '../assets/bin-example1.jpg'
import binExample2 from '../assets/bin-example2.jpg'
import binExample3 from '../assets/bin-example3.jpg'
import binExample4 from '../assets/bin-example4.jpg'
import binb from '../assets/binb.jpg'
import binbExample1 from '../assets/binb-example1.jpg'
import binbExample2 from '../assets/binb-example2.jpg'
import binbExample3 from '../assets/binb-example3.jpg'
import binbExample4 from '../assets/binb-example4.jpg'

import request from '../assets/request.jpg'
import request1 from '../assets/request1.jpg'
import request2 from '../assets/request2.jpg'
import request3 from '../assets/request3.jpg'
import requestb1 from '../assets/requestb1.jpg'
import requestb2 from '../assets/requestb2.jpg'
import requestb3 from '../assets/requestb3.jpg'

export default function Request() {
  const navigate = useNavigate()

  return (
    <>
      <Row>
        <Col xs={{span: 8, offset: 2 }} md={{ span: 5, offset: 0,  order: 'last'}} lg="4">
          <Image fluid src={request} alt="RedUp extra large bin" />
        </Col>
        <Col md="7" xl={{ span: 6, offset: 1}}  className="d-flex align-items-center">
          <div>
            <h1><strong>CHOOSE WHAT WORKS FOR YOU.</strong></h1>
            <p><strong>By selecting the exact number of bins you want, you only pay for the storage you need AND do it all without ever leaving your home.</strong></p>
          </div>
        </Col>
      </Row>
      <Row style={{marginTop: "24px", padding: "6px"}} className="redup-red-background">
        <Col xs="12" className="text-center">
          <h2><strong>Not sure how many bins you need?</strong></h2>
          <h4>Let us help you find the size that is right for you!</h4>
        </Col>
      </Row>
      <Row>
        <Col xs="12" style={{marginTop: "24px"}}>
          <p>
            RedUp offers two bin sizes. The extra large storage bins are 4.0 cubic feet and measure 25 x 18 x 25” around the bottom. The standard size storage bins are 2.5 cubic feet and measure 27 x 17 x 12” around the bottom. Check out the example images to see how your items may fit best in these bins. Check out the example images to get a sense of how items best fit in the bins.
          </p>
          <p>
            If you receive your delivery and don’t think you requested enough, no problem! Just contact RedUp and more bins will be delivered within 24 hours.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="6" lg={{span: 5, offset: 1}} style={{marginBottom: "24px"}} className="text-center">
          <h3 style={{display: "inline-block", minWidth: "300px", maxWidth: "100%"}} className="redup-red-background">EXTRA-LARGE BIN</h3>
          <br />
          <Image src={bin} height="240" alt="XL bin dimensions 18.7w, 25.8l, 15.25h" />
          <Row>
            <Col xs="6"><Image fluid src={binExample1} alt="XL bin example 1" /></Col>
            <Col xs="6"><Image fluid src={binExample2} alt="XL bin example 2" /></Col>
            <Col xs="6"><Image fluid src={binExample3} alt="XL bin example 3" /></Col>
            <Col xs="6"><Image fluid src={binExample4} alt="XL bin example 4" /></Col>
          </Row>
        </Col>
        <Col md="6" lg="5" style={{marginBottom: "24px"}} className="text-center">
          <h3 style={{display: "inline-block", minWidth: "300px", maxWidth: "100%"}} className="redup-red-background">STANDARD BIN</h3>
          <br />
          <Image src={binb} height="240" alt="Standard bin dimensions 17w, 27l, 12h" />
          <Row>
            <Col xs="6"><Image fluid src={binbExample1} alt="Standard bin example 1" /></Col>
            <Col xs="6"><Image fluid src={binbExample2} alt="Standard bin example 2" /></Col>
            <Col xs="6"><Image fluid src={binbExample3} alt="Standard bin example 3" /></Col>
            <Col xs="6"><Image fluid src={binbExample4} alt="Standard bin example 4" /></Col>
          </Row>
        </Col>
      </Row>
      <Row style={{marginTop: "24px"}} className="text-center redup-red-background">
        <Col xs="12">
          <h3>THE MORE YOU SPEND THE MORE YOU SAVE!</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h3 className="text-center">X-LARGE BINS (4.0 CUBIC FEET)</h3>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={request1} alt="10 per xl bin per month" />
          <h4>X-Large bins are $10 each per month.</h4>
          <p>*Minimum of 3 bins for 6 months.</p>
        </Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={request2} alt="9 per xl bin per month" />
          <h2>SAVE 10%!</h2>
          <h4>$9 per bin when you request 5 or more bins.</h4>
          <p>*Minimum of 6 months.</p>
        </Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={request3} alt="8 per xl bin per month" />
          <h2>SAVE 20%!</h2>
          <h4>$8 per bin when you request 10 or more bins.</h4>
          <p>*Minimum of 6 months.</p>
        </Col>
      </Row>

      <Row style={{marginTop: "24px"}}>
        <Col xs="12">
          <h3 className="text-center">STANDARD SIZED BINS (2.5 CUBIC FEET)</h3>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={requestb1} alt="7 per standard bin per month" />
          <h4>Standard sized bins are $7 each per month.</h4>
          <p>*Minimum of 3 bins for 6 months.</p>
        </Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={requestb2} alt="6 per standard bin per month" />
          <h2>SAVE 15%!</h2>
          <h4>$6 per bin when you request 5 or more bins.</h4>
          <p>*Minimum of 6 months.</p>
        </Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={requestb3} alt="5 per standard bin per month" />
          <h2>SAVE 25%!</h2>
          <h4>$5 per bin when you request 10 or more bins.</h4>
          <p>*Minimum of 6 months.</p>
        </Col>
      </Row>
      <Row style={{marginTop: "24px"}}>
        <Col className="text-center">
          <h3>Click the button below to get started!</h3>
          <p>
            <LinkContainer to="/cart"><Nav.Link>
              <Button variant="danger" size="lg" style={{backgroundColor: "#DB0E24"}} onClick={() => {window.scrollTo({top: 0})}}>Get Pricing</Button>
            </Nav.Link></LinkContainer>
          </p>
        </Col>
      </Row>
    </>
  )
}
