import { Accordion, Button, Card, Image, Col, Row, Nav } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import returnBin from '../assets/returnBin.png'
import return1 from '../assets/return1.png'
import return2 from '../assets/return2.png'
import return3 from '../assets/return3.png'

export default function Return() {
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
  const emailAddress = process.env.REACT_APP_EMAIL
  const navigate = useNavigate()
  var itemKey = 0

  return (
    <>
      <Row style={{marginTop: "12px"}}>
        <Col md="8" lg={{span: 7, offset: 1}}>
          <h1>Need your things back?</h1>
          <h3>Just let us know!</h3>
          <p>
            When you are ready, you can contact us via phone ({phoneNumber}) or email ({emailAddress}) to schedule a delivery and specify which bins you would like delivered.
          </p>
          <p>
            The delivery charge is a flat fee of <strong>$19.99</strong> whether you need one bin back or multiple bins back. This price includes a scheduled delivery time and pick up once you are ready to get rid of your bins and send them back into storage.
          </p>
        </Col>
        <Col md="4" lg="3">
          <Image fluid src={returnBin} alt="returning your bins" />
        </Col>
      </Row>
      <Row style={{marginTop: "24px", paddingTop: "12px"}} className="redup-red-background">
        <Col xs="12"><h2 className="text-center">HOW IT WORKS . . .</h2></Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={return1} alt="phone with app logo" />
          <h3>Schedule a Delivery</h3>
          <p>
            When you need access to your items, contact RedUp to schedule a delivery time and specify which bins you would like brought out of storage.
          </p>
        </Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={return2} alt="delivery cart carrying bin" />
          <h3>Get Your Bins</h3>
          <p>
            RedUp will deliver your selected bins during your requested delivery time. Our delivery fee is $19.99 and includes pick up of the vins to take them back into storage.
          </p>
        </Col>
        <Col md="4" className="text-center">
          <Image height="150" style={{marginLeft: "auto"}} src={return3} alt="delivery truck with logo" />
          <h3>Send Them Back</h3>
          <p>
            When you have taken out the items you need and want to get the bins back out of your home, contact RedUp and our team will retrieve the bins within 24 hours and return them into storage.
          </p>
        </Col>
      </Row>
      <Row style={{marginTop: "24px"}}>
        <Col className="text-center">
          <h3>Talk to us anytime for help with your order</h3>
          <p>
            <LinkContainer to="/contact"><Nav.Link>
              <Button variant="secondary" size="lg">Contact Us</Button>
            </Nav.Link></LinkContainer>
          </p>
        </Col>
      </Row>
      <Row style={{marginTop: "24px"}}>
        <Col md="6">
          <h1>Other information on bin deliveries...</h1>
        </Col>
        <Col>
          <Accordion>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How long can I keep the bins before I need to schedule a pick up?</Accordion.Header>
              <Accordion.Body>
                You can have access to the items in your bins for as long as you wish. However, RedUp was designed for quick access service to help you keep clutter out of your homes. We are prepared to take your bins back into storage within 24 hours.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>Do I still pay the monthly storage fee while I have my bins out of storage?</Accordion.Header>
              <Accordion.Body>
                Yes. Paying the monthly fee reserves your space in our storage facility and access to bins.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How many bins can I request per delivery?</Accordion.Header>
              <Accordion.Body>
                The $19.99 delivery fee includes drop off and pick-up of as many bins as you request.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  )
}
