import React, { useState, useEffect } from 'react'
import { Card, Button, Alert, Modal, Row, Col, Form } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase.js'
import { ref, onValue, update } from 'firebase/database'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faTruck, faHouse, faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { functions } from '../firebase.js'
import { httpsCallable } from 'firebase/functions'

import "react-datepicker/dist/react-datepicker.css";

const sendBinRequestUpdate = httpsCallable(functions, 'sendBinRequestUpdate')

function addDays(date, days) {
  var result = new Date(date);
  console.log(result)
  result.setDate(result.getDate() + days);
  return result;
}

export default function Dashboard() {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()
 
  const [binScheduleShow, setBinScheduleShow] = useState(false)
  const [binScheduleRequestKey, setBinScheduleRequestKey] = useState(null)
  const [binScheduleDay, setBinScheduleDay] = useState(null)
  const [binScheduleTime, setBinScheduleTime] = useState(null)

  const [userBinRequests, setUserBinRequests] = useState([])

  const binScheduleTimeOptions = [
    { value: '9am - 12pm', label: '9am - 12pm' },
    { value: '12pm - 3pm', label: '12pm - 3pm' },
    { value: '3pm - 6pm', label: '3pm - 6pm' },
  ]

  useEffect(() => {
    // TODO: validation rules on the state of all this, and make an API call to request a schedule ID that the user can't write to
    return onValue(ref(db, `/users/${currentUser.uid}/bin_requests`), (snapshot) => {
      if (!snapshot.exists()) {
        console.log("no bin request data found, this shouldn't happen...'");
        return;
      }
      const data = snapshot.val()
      console.log("got new binRequests:")
      console.log(data)
      console.log(Object.keys(data))
      setUserBinRequests(snapshot.val())
      //Object.keys(data).map((key) => {
      //    binRequest = data[key]
      //    binRequest.key = key
      //    setUserBinRequests((userBinRequests) => [...userBinRequests, binRequest])
      //})
    }, (error) => {
        alert("error in onvalue: "+JSON.stringify(error))
    });
  }, [currentUser])

  const startScheduleBinRequest = (binRequestKey) => {
      setBinScheduleDay(null)
      setBinScheduleTime(null)
      setBinScheduleRequestKey(binRequestKey)
      setBinScheduleShow(true)
  }

  const finishScheduleBinRequest = (status) => {
    var updates = {};
    if (status === "scheduled") {
      updates[`/users/${currentUser.uid}/bin_requests/${binScheduleRequestKey}/status`] = status;
      updates[`/users/${currentUser.uid}/bin_requests/${binScheduleRequestKey}/scheduled_date`] = binScheduleDay.toISOString().split('T')[0];
      updates[`/users/${currentUser.uid}/bin_requests/${binScheduleRequestKey}/scheduled_time`] = binScheduleTime;
    }
    if (status === "return scheduled") {
      updates[`/users/${currentUser.uid}/bin_requests/${binScheduleRequestKey}/status`] = status;
      updates[`/users/${currentUser.uid}/bin_requests/${binScheduleRequestKey}/return_scheduled_date`] = binScheduleDay.toISOString().split('T')[0];
      updates[`/users/${currentUser.uid}/bin_requests/${binScheduleRequestKey}/return_scheduled_time`] = binScheduleTime;
    }

    update(ref(db), updates);
    setBinScheduleShow(false);
    let safeAddress2 = ""
    if (userBinRequests[binScheduleRequestKey].address.line2) {
        safeAddress2 = ` ${userBinRequests[binScheduleRequestKey].address.line2}`
    }
    sendBinRequestUpdate({
      "status": status,
      "day": binScheduleDay.toISOString().split('T')[0],
      "time": binScheduleTime,
      "address": `${userBinRequests[binScheduleRequestKey].address.line1}${safeAddress2}  ${userBinRequests[binScheduleRequestKey].address.city}, ${userBinRequests[binScheduleRequestKey].address.state} ${userBinRequests[binScheduleRequestKey].address.postal_code}`.trim(),
    })
  }

  const handleBinScheduleReturn = () => setBinScheduleShow(false)

  const isWeekday = (date) => {
    const d = new Date(date)
    const day = d.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <>
      {binScheduleRequestKey &&
          <Modal
            show={binScheduleShow}
            onHide={handleBinScheduleReturn}
            backdrop="static"
            size="md"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              {userBinRequests[binScheduleRequestKey].status == "pending" && <Modal.Title>Schedule Your Bin Delivery</Modal.Title>}
              {userBinRequests[binScheduleRequestKey].status == "delivered" && <Modal.Title>Schedule Your Bin Return Pickup</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
              <div>Choose what time works best for you.</div>
              <div>
                {userBinRequests[binScheduleRequestKey].address.line1} {userBinRequests[binScheduleRequestKey].address.line2} {userBinRequests[binScheduleRequestKey].address.city}, {userBinRequests[binScheduleRequestKey].address.state} {userBinRequests[binScheduleRequestKey].address.postal_code}
              </div>
              <hr />
              <div>
                <DatePicker
                  selected={binScheduleDay}
                  onChange={(date) => setBinScheduleDay(date)}
                  includeDateIntervals={[
                    { start: addDays(new Date(), 0), end: addDays(new Date(), 8) }
                  ]}
                  filterDate={isWeekday}
                  inline
                />
              </div>
              <div>
                Select time window:
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  name="schedule-time"
                  options={binScheduleTimeOptions}
                  onChange={(choice) => setBinScheduleTime(choice.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {userBinRequests[binScheduleRequestKey].status == "pending" && <Button variant="primary" onClick={() => finishScheduleBinRequest("scheduled")} disabled={!binScheduleDay || !binScheduleTime}>Schedule Delivery</Button>}
              {userBinRequests[binScheduleRequestKey].status == "delivered" && <Button variant="primary" onClick={() => finishScheduleBinRequest("return scheduled")} disabled={!binScheduleDay || !binScheduleTime}>Schedule Bin Return Pickup</Button>}
            </Modal.Footer>
          </Modal>
      }
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Dashboard</h2>
          <h3>Bin Orders</h3>
          {Object.keys(userBinRequests).map((key) => (
            <p key={key}>
              <FontAwesomeIcon icon={userBinRequests[key].status == "pending" ? faCircleExclamation : (userBinRequests[key].status == "scheduled" ? faTruck : (userBinRequests[key].status == "delivered" ? faHouse : (userBinRequests[key].status == "return scheduled" ? faTruck : faSquareCheck)))} style={{"margin":"2px 6px"}} />
              {userBinRequests[key].status} - {userBinRequests[key].address.line1} {userBinRequests[key].address.line2} {userBinRequests[key].address.city}, {userBinRequests[key].address.state} {userBinRequests[key].address.postal_code}
              <br />
              {userBinRequests[key].status == "pending" && (<Button onClick={() => startScheduleBinRequest(key)}>Schedule This Delivery</Button>)}
              {userBinRequests[key].status == "scheduled" && (<span>{userBinRequests[key].scheduled_date} {userBinRequests[key].scheduled_time}</span>)}
              {userBinRequests[key].status == "scheduled" && (<span><br/>Your bins are scheduled to be delivered. If you are not home during the delivey window, they will be left at your front door. If you need to make changes, please reach out at <a href="https://redupstorage.com/contact">redup.com/contact</a></span>)}
              {userBinRequests[key].status == "delivered" && (<Button onClick={() => startScheduleBinRequest(key)}>Schedule Bin Pick Up</Button>)}
              {userBinRequests[key].status == "return scheduled" && (<span>{userBinRequests[key].return_scheduled_date} {userBinRequests[key].return_scheduled_time}</span>)}
              {userBinRequests[key].status == "return scheduled" && (<span><br/>Your bins are scheduled for pickup. If you will not be home when your bins are picked up and weather permitting, please leave them clearly visible outside your door or garage. If you need to make changes, please reach out at <a href="https://redupstorage.com/contact">redup.com/contact</a></span>)}
              {userBinRequests[key].status == "returned" && (<span>Your bins are all set! Want your items back? Please reach out at <a href="https://redupstorage.com/contact">redup.com/contact</a></span>)}
            </p>
          ))}
        </Card.Body>
      </Card>
    </>
  );
}
//{binRequest.address.line1} {binRequest.address.line2 ? binRequest.address.line2 : ""}
              //{binRequest.city}, {binRequest.state} {binRequest.postal_code}
