import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
// import { Link, useNavigate } from 'react-router-dom'

export default function Login() {
  const emailRef = useRef()
  const { currentUser, isSignInLink, triggerLogin, confirmLogin } = useAuth()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  // const navigate = useNavigate()

  async function handleLogin(e) {
    e.preventDefault()

    if (emailRef.current.value.length < 4) {
      // TODO(matt): proper email validation
      return setError("Email must be 4 or more characters")
    }

    try {
      const loginEmail = emailRef.current.value
      setError("")
      setLoading(true)
      const status = await triggerLogin(loginEmail)
      if (status != "ok") {
        setError(status)
        setLoading(false)
        return
      }
      window.localStorage.setItem('emailForSignIn', loginEmail)
      setSuccess("A link has been sent to log in with, you can close this page now.")
    } catch (err) {
      console.log(err)
      setError("failed to log in: "+err.code)
      setLoading(false)
    }
  }

  async function handleConfirm(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await confirmLogin(emailRef.current.value)
      console.log("successfully confirmed, will be automatically redirected")
    } catch (err) {
      console.log(err)
      setError("failed to confirm: "+err.code)
      setLoading(false)
    }
  }

  const confirming = isSignInLink();

  useEffect(() => {
    if (confirming) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        emailRef.current.value = email
        setError("")
        setLoading(true)
        confirmLogin(email).then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          console.log("successfully logged in, will be automatically redirected")
        }).catch((err) => {
          console.log(err)
          setError("failed to confirm: "+err.code)
          setLoading(false)
        });
      }
    }
  }, [confirming, confirmLogin])

  return (
    <Row><Col lg={{span: 6, offset: 3}}>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Log In</h2>
          {currentUser != null && currentUser.email}
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={(confirming ? handleConfirm : handleLogin)}>
            {confirming && <p>Please confirm the email address you are signing in with:</p>}
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control disabled={loading} type="email" ref={emailRef} required />
            </Form.Group>
            {!confirming && <Button disabled={loading} className="w-100 mt-4" type="submit">Log In</Button>}
            {confirming && <Button disabled={loading} className="w-100 mt-4" type="submit">Confirm</Button>}
         </Form>
        </Card.Body>
      </Card>
    </Col></Row>
  )
}
