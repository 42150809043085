import { Button, Card, Image, Accordion, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Privacy() {

  return (
    <>
      <Row style={{marginTop: "48px"}}>
        <Col lg={{span: 10, offset: 1}} xl={{span:8, offset: 2}}>

<div>

<h3><strong>Privacy Policy</strong></h3>
<p>RUS takes the safety and security of user information seriously and is committed to protecting user privacy. In accessing or using RUS Services in any manner, users acknowledge and accept the policies outlined in this Privacy Policy and hereby consent to the gathering, use, and sharing of user information by RUS in the described ways.&nbsp;</p>
<p>Keep in mind that at all times use of RUS Services by the user is subject to the Terms of Service of which this Privacy Policy is included. Should a term used in this Policy not be defined the description can be found in the Terms.&nbsp;</p>
<p><strong>General Policy Structure</strong></p>
<p>The RUS Privacy Policy governs the way in which we treat personal information that is gathered when users access or use RUS Services. However this does not apply to the treatment of that information from companies RUS does not control or own, nor does it apply to those who are not an RUS team member. RUS gathers different kinds of personal information and uses it internally for our Services, including but not limited to analyzing and improving our Services and providing a more personalized experience, and allowing users to create a personal user profile with account information that allows RUS to contact users and execute requests for the Services and products provided. RUS may also share, in specific cases, personal information with third parties as described later in the Privacy Policy.&nbsp;</p>
<p>RUS requires an individual user must be a real person over the age of eighteen (18) years and competent to enter into contracts in their own name. RUS acknowledges that the The Children’s Online Privacy Protection Act (“COPPA”) states parental consent must be obtained by digital service providers before collecting personal information from children younger than thirteen (13) years of age. RUS will never intentionally or knowingly collect or seek out personal information from children under the age of 13. Users may contact RUS at <u>contact@redupstorage.com</u> if they believe a child under the age of 13 has provided RUS with personal information.&nbsp;</p>
<p>RUS may alter its Privacy Policy periodically. Users will be alerted by RUS to any changes made to this policy by sending a notification to their email, putting such notice on the website, redupstorage.com, or by some other manner. Should a user not have provided RUS with their email address, such legal notices will still govern their use of RUS Services and users shall remain responsible for reading and understanding said legal notices. Continuing to use RUS Services after changes to the Privacy Policy have been made is agreement by the user of those changes. Use of information gathered is subject to the Privacy Policy and in effect at the same time that information was collected by RUS.&nbsp;</p>
<p><strong>Types of Information Collected</strong></p>
<p>Any information knowingly provided by users to RUS is gathered and stored. This includes, but is not limited to, personal information provided through website registration, account settings, and/or over the phone, and such personal information may include the user’s name, phone number, email address, home address, or login/account information for third-party accounts. If a user’s third-party account information or login credentials are provided or the user signs in to RUS Services through a third party site or service, they understand some information and/or content in those accounts may be transferred to their user account with RUS should they authorize such a transfer and that said information and/or content is included in this Privacy Policy. An example includes that RUS will collect the user’s name and email address in order to identify the user’s account when logging in for Google or Facebook. Some information such as this may be required for the user to access some features of RUS.&nbsp;&nbsp;</p>
<p>RUS may communicate with users periodically if users provided a way in which to do so. If users provide their email address, RUS may send emails about the use of Services or promotional emails related to RUS or other businesses. RUS may also receive confirmation when a user opens an email sent to them from our team. Such confirmation assists RUS in improving Services. Should users not wish to receive promotional emails, they may unsubscribe.&nbsp;</p>
<p>When users access or interact with Services, RUS will automatically have a record of information on our server logs from the user’s device or browser and such information may include geolocation data, IP address, device identification, “cookie” information, the feature or page requested, or the type of device and/or browser used. RUS uses “cookies” as an identifier transferred to the user’s browser or device that will allow our systems to recognize them and alert us to when and how features and pages in RUS Services are visited and by how many users they are visited by. Users may be able to alter their browser preferences or change their device preferences to limit their use of cookies, though doing so may limit user’s ability to take full advantage of some features of RUs.</p>
<p>Should a user click a link to a third party service or website, that third party may also transmit cookies to the user. This Privacy Policy does not cover cookies or their use from any third party and RUS is not responsible for those practices or policies of those third parties. Users should be aware that third party cookies may continue to track online user activities even after they have left our Services, and such third parties may not acknowledge user requests on not tracking they have set in their device or browser.&nbsp;</p>
<p>RUS may use data to create customized user content based upon patterns in use. Such information may also be used to improve the Services in different ways, an example being that by understanding how often a certain feature of the Services is used RUS can enhance that feature and make it more effective for all users.&nbsp;</p>
<p>RUS may collect information on user's online activity after leaving our Services through cookies. This information also allows RUS to improve Services and enhance user experience by customizing such online experience and otherwise as outlined in the Privacy Policy. RUS Services does not currently support “do not track” requests that may be set or controlled by the user’s browser, meaning RUS may collect user’s online activity while they are using Services and after they leave our Services online.&nbsp;</p>
<p><strong>Information Security</strong></p>
<p>User accounts with RUS and through <em>redupstorage.com</em> are password protected to ensure their security and privacy. Should a user access their account through a third party service or site, the user may need to have a different or additional sign-on protection process through that third party service or site. Users are responsible for preventing unauthorized access to their online account and the information contained within by ensuring to use and protect their own password or other sign-in process and by monitoring and protecting access to their own device and browser and ensuring they log out once finished using their account.&nbsp;</p>
<p>RUS strives to protect the security and privacy of user accounts and their personal information recorded by us, however we cannot guarantee absolute security of such information. Any kind of hardware or software failure, unauthorized entry or use, failure or other type of factor may compromise the security or put at risk user information at any point in time.&nbsp;</p>
<p><strong>Information Sharing</strong></p>
<p>RUS may share a de-identified version of user’s personal information with partners so that they are not individually identifiable. RUS may also provide information on aggregate usage to partners or allow them to collect that information from the users and who may use that information to understand in what ways and how often users access or Services so that the online experience can be enhanced. RUS will never share de-identified or aggregate information to a partner or allow them to collect that information in a way that would allow a user to be individually identified, provided that certain personal information in relation to business transfers. RUS will use web analytics and other digital assessments to understand how users access the Services. In doing so, RUS uses third-party software development kits (“SDK”) to track and assess the use of Services. An example is Google Analytics. The third-party SDK may allow other third parties to gather users' personal information and use it to offer customized content. SDK may also use cookies to understand how often users visit or access Services, including which web pages they visit most and what other sites were used before access of Services. RUS may use information gathered from SDK to enhance Services and/or share promotional information about RUS. Part of SDK data gathered is shared with other online services such as Google, who may use said data to customize ads for users within its own network. Go to Google Privacy &amp; Terms web page to read more on the practices and privacy policies of Google.</p>
<p>RUS may give a file to users from an ad network through Services. This web beacon allows ad networks to give aggregated auditing, anonymized, reporting and research to advertisers and RUS. These web beacons also allow ad networks to give targeted and specific ads to users when they visit the RUS website or access or Services. These companies have the ability to edit, view, or transfer their own cookies to the user just as though the user requested a page directly from their website since the user’s own browser must request these web beacons and advertisements from the servers of the ad network. Users may have the ability to opt-out of tracking from web beacons from third parties through Services by changing the do not track settings on their personal browser. However RUS does not control how or whether third parties comply with and honor do not track requests.&nbsp;</p>
<p>Other businesses or third party sites RUS is affiliated with may, in specific situations, provide or sell services or products to users in connection to or through our Services, either in conjunction with or independently of RUS. When an associated business is connected to a service or transaction, the user will be able to recognize it and RUS will share with that associated business their personal information, limited to what is related and/or necessary to said service or transaction. RUS has no influence or control over third party website’s or businesses policies, practices, or privacy. In such a case where users choose to participate in any services or transactions with associated businesses or websites, users should review their policies.&nbsp;</p>
<p>RUS contracts other individuals and companies to carry out Services. In doing so RUS will share user’s information with them in order to provide those requested products and Services. This includes, but is not limited to, an online payment processing site that processes user’s credit card translation for RUS Services. RUS uses Stripe as our current payment processing provider, which means RUS does not directly store any payment information from the user. However, user account information such as name and email are shared with Stripe in order to make their payment service function properly. Unless otherwise notified, RUS associated companies, individuals, or other agents do not have the right to use personal account information shared with them beyond what is necessary for their service in connection to RUS. In certain circumstances such “agents” may also be referred to as “partner” and are also subject to the terms related to the sharing of information that has been de-identified.&nbsp;</p>
<p>RUS may choose to sell or buy assets, and in doing so may transfer and/or share user information as it relates to the assessment and participation into such a transaction. Additionally, should RUS or our assets be acquired by another business, enter bankruptcy, go out of business, or change ownership/control, user’s personal information may be included in the transfer of assets or be those acquired by a third party.&nbsp;</p>
<p>RUS shall reserve the right to preserve, read, disclose, and access any user information we believe is reasonably necessary to comply with a court order or law, applies to or is enforced by our Terms and Agreement, or protects the safety, property, and rights of RUS, including but not limited to its team members, subcontractors, users.</p>
<p><strong>User Access</strong></p>
<p>The information users provide to RUS that they may access and/or alter in some cases are their email address, name, password, phone number, home address or address for requested Services, billing address, and other relevant billing details and information. Such information may be viewed by the user and may also be updated or deleted, and may change should RUS Services alter or expand. Should users have any questions about their personal information they provided to RUS, they may contact a team member.&nbsp;</p>
<p>Should a user wish, they may choose not to disclose certain information to RUS, however some information may be specifically needed to sign-up with us or use RUS Services. Users may be able to continually add, delete, or update the kind of information specified in this policy, though should alterations be made, RUS may continue to have a record of previous unrevised information and/or information that was deleted from the users account. RUS may use aggregate data that includes the user's personal information after it was deleted or altered, but such data shall not be used in a way that allows the user to be personally identified.&nbsp;</p>
<p><strong>Contact us</strong></p>
<p>Should a user have any concerns or questions on this privacy policy, please contact an RUS team member via email or over the phone. </p>


</div>

        </Col>
      </Row>
    </>
  )
}
