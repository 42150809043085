import { Button, Card, Image, Accordion, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Terms() {

  return (
    <>
      <Row style={{marginTop: "48px"}}>
        <Col lg={{span: 10, offset: 1}} xl={{span:8, offset: 2}}>

<div>

<h3><strong>Terms of Service</strong></h3>
<p>Hello and welcome to RedUp. Your registration with RedUp Storage, LLC ("RUS", “our”, “we”, “us”) and your purchase of our services is subject to the following terms and conditions of service ("the Terms"), together with our website terms of use and policies, <em>www.redupstorage.com</em>, which are incorporated into these Terms.</p>
<p>This document sets forth the Terms and related conditions that dictate all use of <em>www.redupstorage.com</em> and all of the RUS services, including any apps for your phone or other electronic devices. These Terms establish your rights and the rights of RUS and create the storage agreement between us as storage operator and you as the user and bind us to the Terms of this Agreement.</p>
<p>In this Agreement, the term “the Services” means the storage of Your Items (“Your Items”) in a storage facility and does not refer to any services not related to storage. The term “the Services” does include all the logistical and administrative services related to the storage of Your Items (which includes but is not limited to pick-up, storage, and delivery of personal items using various third party subcontractors selected by RUS, such as moving companies, delivery companies and storage facilities - RUS does not have liability for the acts or omissions of these third parties or various subcontractors). The Terms outlined and described here apply to all users, visitors, and any others who access the RUS website or purchase and subscribe to RUS Services (“Users”, “they”, “their”, “you”, “your”). Use of the Services is based upon consent to all Policies - the Privacy Policy, Pricing Standards, and Rules of Storage (which are all incorporated and referenced throughout this document), and the Terms (“the Agreement).</p>
<p>Any and all RUS services you have purchased and consented to will be extended for repeating renewal periods automatically and will be for the same duration as the originally selected term and at the then-standard rate. To change or cancel your services you will be subject to the “Canceling Your Services” policies included in this document.&nbsp;</p>
<p>READ ALL TERMS OF SERVICE CAREFULLY BEFORE CONSENTING OR PURCHASING OUR SERVICES. BY AGREEING TO THESE TERMS YOU ARE ACKNOWLEDGING YOUR UNDERSTANDING OF THESE TERMS AND ACCEPT THEM. THIS INCLUDES ALL ADDITIONAL POLICIES REFERENCED IN THIS DOCUMENT AS WELL AS THE DISPUTE RESOLUTION AND ARBITRATION RESOLUTIONS OUTLINED WITHIN. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT USE OR PURCHASE RUS SERVICES.&nbsp;</p>
<p><strong>GENERAL CONDITIONS OF RUS USE</strong></p>
<p><strong>User Eligibility</strong>&nbsp;</p>
<p>RUS only allows for personal, non-commercial storage use. RUS storage may not be used for a third party or used in any way or for any purpose that is prohibited by law.&nbsp;</p>
<p>An individual user must be a real person over the age of eighteen (18) years and competent to enter into contracts in their own name. The legal name of an individual user must be provided and match the one on their government-issued identification. Users must be the sole owner of all property stored with RUS and not share interest or ownership of said property with others or attempt to store property with a lien from a third party. By accepting this Agreement, you confirm you are the sole owner of the property and have the right to store it with RUS.</p>
<p>RUS may agree to accept payments at your request and made on your behalf from a third party. Regardless of payments from a third party you remain liable for all obligations to RUS. Third parties in this capacity do not have any rights to Your Items according to these terms unless a separate agreement has been established and signed by RUS and all involved parties.&nbsp;</p>
<p>Please notify RUS if you are an active Military member. Members of the “Military” include the US Navy, US Army, US Air Force, US Marine Corps, or US Coast Guard on active duty status (as well as those reservists called to active duty) or if absent from duty due to being granted leave or wounded, a member of the National Guard with a certain kind of activation order, or a commissioned officer on active service with the Public Health Service or with the National Oceanic and Atmospheric Administration. If changes occur to your Military status or previously registered profile information with RUS, please notify RUS of those changes by contacting us at <u>contact@redupstorage.com</u>. If you become an active Military member after subscribing to Services with RUS and consenting to this Agreement, please contact RUS with your updates.</p>
<p><strong>Service Areas&nbsp;</strong></p>
<p>RUS is a locally provided and regional based service. The way in which RUS provides the Services subscribed to shall be based upon the location of Your Items when the Services are requested (“Local Service Zone”). The Local Service Zone is based on the address provided by the user when requesting RUS Services in relation to our Service Area.</p>
<p>RUS currently operates in Northeast Ohio counties (“Service Area”). Operating hours may vary based upon Local Service Zones. If necessary RUS has the right to use variable pricing based upon the Local Service Zone as the area may influence the cost of resources and the price of Services. As a user you understand and consent that while you are able to digitally access Your Items, RUS is not obligated to store Your Items in a specific Local Service Zone location.&nbsp;</p>
<p>If you move out of the United States after subscribing and consenting to RUS Services in the United States, you must notify RUS of changes to your contact information. It is understood by the user RUS will not allow any international deliveries or shipments of Your Items unless a special agreement has been reached with RUS and consented to by all involved parties. </p>
<p><strong>Privacy &amp; Electronic Contract</strong></p>
<p>RUS takes the safety and security of user information seriously and is committed to protecting user privacy. For the most current version of the RUS Privacy Policy, please read <a href="/privacy-policy">here</a>.</p>
<p>Agreeing to the Services provided by RUS by clicking the “Confirm Delivery” button is your electronic signature. By agreeing with those Services electronically, scheduling a delivery over the phone, or otherwise, that is your agreement to conduct transactions with RUS electronically. In this agreement you consent for RUS to use and maintain an electronic record. This record may be provided or made available in writing to the user with information required by regulations, statutes, or other laws with regards to transactions conducted with RUS.&nbsp;</p>
<p>Users are responsible for printing or storing these electronic records for personal reference. Upon a user subscribing to RUS Services, the confirmation sent of the subscription will represent our signature and compliance with this electronic agreement. A signed confirmation will also be sent electronically when Your Items have been received into storage (“Storage Confirmation”). Receiving it in your inbox will represent your acknowledgement of receiving the Storage Confirmation which is the acknowledgement you were given adequate time to review and question your signed Storage Confirmation and will also represent your electronic signature on the document. This includes your consent that no certification or other third party is needed to validate your electronic signature and that absence of such verification does not in any way influence the enforceability or validity of your electronic signature or the agreement. </p>
<p><strong>RUS Website &amp; Forthcoming Software Application</strong></p>
<p>All RUS content included on the website, <em>www.redupstorage.com</em>, and corresponding software that it is managed by are the property of RUS, RUS licensors, or other RUS content suppliers and that content is protected by laws as well as copyright and trademark regulations. RUS is the owner of all website and future corresponding Application content which includes trademarks and logos. RUS reserves all rights on its content and software and you may not use or access RUS content without our explicit consent.&nbsp;</p>
<p>Upon consent to these Terms and user compliance, you as a user are given non-transferable, limited, non-exclusive, non-sublicensable license to access and use RUS features for personal and non-commercial use only. Other uses beyond fair use are prohibited without explicit consent from RUS.&nbsp;</p>
<p>Users agree to not engage in the prohibited activities of spidering, page-scraping, or other automated access or an equivalent manual process. Users cannot copy or monitor RUS or in any way circumvent the structural components or navigation of RUS. Users will not hack, attempt to hack, phish, crack, or take any other illegitimate action against, RUS systems and its servers. A user will also agree to not accept or attempt to gain unauthorized access to RUS data or any part of RUS systems. This includes other systems, networks, and servers connected to RUS or working in conjunction with RUS. Users agree they will not nor will even attempt to scan or test the weaknesses or vulnerabilities of RUS or breach or attempt to breach security or authentication measures.&nbsp;</p>
<p>Users also agree to not engage in the prohibited activities of reverse look-up, cross-site scripting, IP tracing, or other methods used to collect information on any other user or visitor of RUS. Users agree to never partake in activity that could impose a disproportionately large load on RUS or its connected systems and servers. Users will not copy, modify, decompile, reverse engineer, creative derivative works, or in any way attempt to collect source code or elemental ideas or information of or relating to RUS. Users will not contact RUS acting as someone else or attempt to disguise or mask the origins of messages or packets. Users will not manipulate raw TCP/UDP streams or participate in other unauthorized conduct.&nbsp;</p>
<p>Users agree they will not now or ever use RUS in violation of any laws or regulations. RUS reserves the right to bar any other activities that could be detrimental in any manner to RUS or its users. Users will comply and be subject to any updates to these prohibitions. </p>
<p><strong>Canceling Your Services&nbsp;</strong></p>
<p>You as the user may initiate the process of closing your account and stopping your service subscription with RUS by contacting us at any time. Any fees for the delivery of Your Items or the Minimum Commitment will still apply. You as the user are also responsible for any administrative costs related to the canceling of the Services and must continue to pay for your subscription until all of Your Items have been returned. </p>
<p><strong>Violation of RUS Agreement&nbsp;</strong></p>
<p>RUS reserves the right to terminate your RUS account and service subscription at any time with or without cause. If you are terminated without cause that termination will be subject to thirty (30) days advance written notice unless you waive your rights to such a notice. If RUS terminates your account for cause (such as a violation of the Terms, Privacy Policy, or Rules of Storage), you will be notified of the termination and have thirty (30) days to collect Your Items. This will be at your cost or charged to you through RUS and is subject to our Pricing Standards.&nbsp;</p>
<p>If you have placed items in storage with RUS in violation of the Terms and Rules of Storage, that have the potential to contaminate other items and put RUS or the items of other customers at risk, or pose a risk to human health in any way, notwithstanding other relevant Terms or lien law rights RUS reserves the right to terminate your account and/or dispose or quarantine those items at your personal expense. In such an event no compensation is owed to you. RUS will alert you to arrange for quarantine or other safety protocols needed for the care of such hazardous material.&nbsp;</p>
<p>You as the user are responsible for all actions taken involving RUS. To the fullest extent permitted by law the user agrees to defend, indemnify, and hold RUS harmless from all losses, damages, liabilities, claims and expenses that arise from violation of the Terms, Storage Rules, or Privacy Policy or Your Items you stored with us. This extends to all RUS employees and affiliates. </p>
<p><strong>RUS LIMITATIONS ON LIABILITY, COMPANY RIGHTS, AND USER RESPONSIBILITY&nbsp;</strong></p>
<p><strong>Rules of Storage &amp; Conditions&nbsp;</strong></p>
<p>The Rules of Storage are part of the Agreement and clear rules have been established regarding what you can and cannot store with RUS. If you as a user are found to have violated the Rules of Storage that will put you in breach of the Agreement. RUS reserves the right to refuse to store any items at our sole discretion even should that item not be explicitly banned in the Rules of Storage. The Rules of Storage also address the storage of valuable, fragile, or other unique items and such items are subject to additional policies and limits on RUS liability. You can find the complete Rules of Storage <a href="/rules">here</a>. By agreeing to the Terms in this Agreement you agree to these policies included in the Rules of Storage.&nbsp;&nbsp;&nbsp;</p>
<p>Your Items will be stored with RUS in a storage facility and where racking may be used or bins placed on pallets. Your Items will not be stored in a completely climate-controlled facility where heat, air flow, or moisture are regulated, though efforts will be made to maintain an agreeable temperature range. RUS takes reasonable steps to protect Your Items from mold, however because bins are not stored in a completely climate-controlled environment RUS cannot guarantee no such mold or growth may occur on Your Items. RUS is not liable for any kind of natural growth, mold, or mildew, and users understand the risk that mold or a similar growth could develop under such storage conditions.&nbsp;</p>
<p>Users acknowledge and agree that when storing Your Items, RUS has the right, at our sole discretion, to move Your Items to another storage facility in the United States. Should RUS move Your Items to another storage facility such a transfer will not be an additional cost to the user during the time Your Items are in storage with RUS. Users have the right to request information on the condition of Your Items at any time by contacting RUS.</p>
<p>RUS team members and subcontractors reserve the right to open and inspect Your Items in the event of an emergency, suspected violation of the Rules of Storage, suspected presence of prohibited items, or suspected criminal activity. If RUS receives a search warrant from government officials or those from a government agency, RUS may allow your items to be searched and, if necessary, the items be seized without incurring any liability to the user. If RUS were to receive a subpoena or a government agency or law officer requests information or documents about Your Items, users consent that RUS may provide such information without incurring any liability to the user.&nbsp;</p>
<p>RUS will not involve themselves in property disputes regarding Your Items unless a court order or binding legal ruling is presented. Should another person or party tell RUS they are the actual owner of any of Your Items, they must complete a form with RUS that under penalty of perjury makes their claim. This person or party will agree to indemnify RUS, its team members and subcontractors, and the user for any costs that are incurred should their claim be wrong and agree to provide the option for arbitration to resolve the claim, without RUS as a participating party, and agree the person or party defeated in such arbitration (“loser”) compensate all party’s legal fees. If such a situation occurs RUS will provide a copy of the claim to the user and a form on which the user may respond or dispute the claim to the property made by others. If the user disputes the claim it becomes the user's responsibility to solve such a matter and RUS will take no additional action - though in such a case the user does have the option to loser-pays arbitration. Users have thirty (30) days to dispute other claims of ownership and if no action is taken RUS will return the disputed property to the user and will notify the other person making a claim to the items that they have been returned. By consenting to this Agreement, users agree they may be billed for any and all costs related to the return of such disputed property. </p>
<p><strong>Packing Your Items&nbsp;</strong></p>
<p>You as a user are responsible for the manner in which Your Items are packed. This includes the safety and security of the packing style and use of packing materials (such as wrapping papers, foam, or bubble wrap), to ensure Your Items are secure and to prevent damage during the transfer and storage involved with RUS Services. The manner in which Your Items are packed sets the condition in which it will be returned and also determines the limitations on RUS liability should something be damaged. For user safety, the safety of our RUS team members, and the safety and security of Your Items, RUS recommends packing no more than 30 to 35 pounds of Your Items into an RUS bin. RUS reserves the right to refuse Your Items if we believe the bin weighs more than 45 pounds. RUS also reserves the right to re-pack or ask you to re-pack Your Items before transferring them into storage to ensure safety.&nbsp;</p>
<p>Users understand that Your Items will be moved periodically in relation to deliveries or as needed for its storage. When requesting RUS pick up Your Items, you are confirming (represent and warrant) that the Your Items have been packed appropriately and are prepared to undergo the Services related to storage. Users understand and agree that RUS is not responsible for any damage caused due in any way to your failure to properly pack Your Items. Should damaged items cause a hazard or are in any way pose a risk to RUS team members, RUS may be unable to return the item and may be required to dispose of said damaged item. If said damaged item caused additional damage to other items in the same bin or to other bins, RUS reserves the right to dispose of other damaged items due to your storage of a fragile or prohibited item or failure to properly pack an item that became unsafe or hazardous. RUS also reserves the right to decline to accept Your Items if they appear too poorly packed for Services related to RUS storage. RUS may require you to use additional packing supplies at your own expense or purchase additional supplies through RUS and/or waive or additionally limit our liability before RUS will transfer Your Items into storage. </p>
<p><strong>Pick-ups and Deliveries</strong></p>
<p>Users understand bin deliveries happen following consent to the Terms and all policies of the Agreement, and when payment of the requested Services with RUS has been processed. Users consent to and understand that deliveries of empty bins for personal packing occur during RUS operating hours and may be left outside their home or the designated location of delivery. It is the users responsibility to be home or at the designated location for new bin delivery should they choose. Users will receive confirmation when a new bin delivery has been made and receipt of that bin delivery to their inbox will represent their acknowledgement that they understand bins have been left for them.&nbsp;</p>
<p>Users must have their bins packed and organized before initiation of any further Services. It is the users responsibility to be home or at the designated location of the bin pick-up, or to otherwise ensure through other arrangements that RUS can carry out the Services related to the pick-up of Your Items when specified. It is also the users responsibility to be home or at the designated location or to otherwise ensure through other arrangements that RUS can carry out the Services related to the delivery of Your Items when specified. It is the users responsibility to make appropriate arrangements should their building or residence require reservations for elevators or loading docks, or any other kind of advance notice for pick-up and/or deliveries. If Services are delayed or extended due to user’s lack of preparedness, RUS service fees will be applied to the time during which team members were delayed.&nbsp;</p>
<p>RUS may require user’s to confirm their identity before a scheduled Service either online, in person, or in any other way as determined by RUS and it will be the user’s responsibility to verify their identity. Scheduled Services may also be confirmed by RUS with the user and may be done digitally or over the phone. It is the responsibility of the user in such a case to confirm their scheduled Service and all elements related to the requested RUS Service as it relates to the storage and transfer of their items. RUS may also require users to agree with the Terms explicitly before their first scheduled service and periodically afterwards should the Terms be updated. Users understand each time they give RUS items to put into storage they again agree to these Terms. When a confirmation is provided RUS does not guarantee availability of Services at a certain time, and users agree they understand RUS cannot guarantee specific timing but will give its best efforts.&nbsp;</p>
<p>RUS reserves the right to cancel scheduled services if users are not prepared or for any other reason identified above, including but not limited to failure to confirm scheduled Services, failure to confirm your identity, failure to be present for pick-up or delivery, failure to provide a safe environment, failure to make necessary arrangements to provide Service at your place of residence, or failure to confirm consent to the Terms. RUS may also cancel Services if requested or occurring outside standard operating hours and operating procedures, if there is evidence of criminal activity, eviction, police action or unrest, or if RUS believes users do not intend to use RUS Services in compliance with the Terms. Any cancelation of Services related to the reasons in this paragraph qualify as cancelation for cause. All RUS team members and subcontractors will not be liable to the user if RUS or its team members and subcontractors cancel Services for any reason.&nbsp;</p>
<p>Should the users contact information or address of residence, mailing, or billing change, users must update their profile with RUS within ten (10) days. RUS is not responsible if notice of Service or notice of any kind was not received by the user due to the fact that their contact information or address changed and they did not update their profile. Users represent and warrant that they are the owner of any phone, cell phone, or mobile phone number provided in their information to RUS and that users will remove any number in that information if the number is transferred or surrendered to someone else. Users agree that RUS may contact them through any information provided in their user profile. </p>
<p><strong>Protection &amp; Limited Liability&nbsp;</strong></p>
<p>The most important way to ensure safety and security of Your Items is to make sure they are properly packed. The manner in which Your Items are packed is the sole responsibility of the User, and as previously stated, RUS recommends that additional packing materials be used (such as wrapping papers, foam, or bubble wrap).&nbsp;</p>
<p>If loss or damage occurs to any of Your Items that have been properly packed and stored with RUS, such as they have been tampered with, lost, or stolen while in RUS’s possession, or damaged due to RUS’s willful misconduct or gross neglect, RUS will reimburse the user $0.60 per pound for those items. RUS does not reimburse for the actual value of items up to $0.60, but instead reimburses users on an item-by-item basis per pound of that item’s weight. Any damage to a user’s home due to RUS’s willful misconduct or gross neglect, RUS will reimburse up to $500.&nbsp;</p>
<p>RUS reserves the right to decrease or deny reimbursement if damage was caused or contributed to by the user’s or a third party’s neglect or willful act. RUS reserves the right, and its sole discretion, to inspect and determine the nature of any damage to Your Items stored with RUS and your household, and the value of such items. RUS has no liability for the loss of or damage to any of Your Items if the user has in any way breached the provisions of the Agreement, or if the user has failed to comply with any of the Terms. This includes but is not limited to failure to properly and safely pack Your Items for storage, failure to notify RUS of lost or damaged items within the required time period, failure to provide the necessary information on claims of lost or damaged items. These liability limitations of RUS and the Agreement with RUS is not an insurance policy and should not be treated as a replacement for one.&nbsp;</p>
<p>To be eligible for reimbursement, users must notify RUS of any loss or damage immediately and submit their claim within five (5) days of the delivery of Your Items or the Service event when household damage occurred. RUS will investigate the cause of loss or damage to Your Items extensively upon receiving such a notice. Such an investigation may vary in length depending upon the nature of the claim. RUS team members will provide updates to the user and timetables during such an investigation. RUS is entitled to and may require proof of purchase or presence of an item in a storage bin with RUS, including but not limited to photographs taken of such missing or damaged items prior to packing. Users agree that&nbsp; RUS will have no liability for any lost or damaged items should they fail to provide requested proof in a user claim or when requested. Users are still responsible for paying all associated Service fees regardless of the status of any claims made with RUS, even if a fee is connected with an item that is part of the users claim. Users may not attempt to recoup fees paid to RUS at any time or initiate a chargeback. Failure to pay fees or the initiation of a chargeback will be taken into consideration and assessed by RUS when determining a claim outcome. Should it be found after an investigation that reimbursement is owed to the user, RUS will arrange a separate payment. This circumstance does not make a user eligible for any refunds of paid fees, credit towards a future fee, or forgive an unpaid debt to RUS.&nbsp;</p>
<p>Under no circumstance will RUS be liable for any damages in an excess of the amount specified in these Terms. RUS does not have any liability for certain damages, defects, or other claims related to special, valuable, and fragile items as defined in the Rules of Storage. Upon consenting to this Agreement, users agree they have carefully reviewed these terms and should Your Items be lost, stolen, or damaged, the liability for each item with RUS, and its team members and subcontractors, are limited to the amounts described in these terms. If fragile or valuable or otherwise special items are stored against the advice and recommendations outlined in these Terms, users do so at their own risk. The liability limitations of RUS, and its team members and subcontractors, as described in these Terms are an important part of the Agreement and by consenting to the Agreement users acknowledge that no RUS Services shall be offered without it. </p>
<p><strong>Insurance</strong></p>
<p>Should bodily injury or claims to damage of property occur while RUS team members or subcontractors are in your home or requested location of Service, RUS agrees it will cause the user to be listed as an “additional insured” on liability insurance. Coverage of the user as an additional insured shall be subject to existing policy limits and the terms, conditions, and limitations of the insurance policy. Users agree they rely solely on available insurance to provide defense and indemnity with any claim that occurs during RUS Services at their home or selected location, even if for any reason the insurer for RUS denies the user coverage. Users will not have nor can they claim any right to indemnity, contribution, or defense from RUS connected to any liability claim made against the user except as required by law. Should a user suffer bodily injury or property damage outside of Your Items while RUS team members or subcontractors are at your home or selected location carrying out RUS Services, users agree RUS liability will be limited to available insurance limits, and users will not collect damages they suffered or any judgment a user is awarded from non-insurance assets with RUS. Also upon request from the user in writing, RUS may agree that your landlord or other entity with property interest at your selected location can be an additional insured under general and automotive liability insurance for RUS team members or subcontractors.&nbsp;</p>
<p>RUS does not maintain insurance on Your Items that users may make a claim under. Users have the option of obtaining their own personal coverage for items stored with RUS and if users desire coverage above the limits in the protection and liability limitation with RUS that is their sole responsibility. If users do not obtain their own personal insurance coverage they understand that RUS is not liable beyond the specified amounts and conditions included in these Terms. </p>
<p><strong>Further Limitations of Liability&nbsp;</strong></p>
<p>RUS and its team members, subcontractors, licensors, suppliers, and all other third party contractors will not be liable under tort, strict liability, contract, negligence, or any other legal theory for any indirect, incidental, special, consequential, or punitive damages or lost profits even if disclosed by the user, for any viruses, trojan horses, bugs or similar technical issues regardless of their original source, or for any damages that are in excess of (in the aggregate) $100.00. These limitations within the Terms apply even if user remedies within this agreement fail their essential purpose. To the extent prohibited by applicable law these limitations shall be void.&nbsp;</p>
<p><strong>Indemnification</strong></p>
<p>Users will indemnify and hold RUS, its team members, and its subcontractors, harmless from any loss incurred by RUS and its team members and subcontractors in any way related to Your Items and use of RUS Services to the fullest extent permitted by law.&nbsp;</p>
<p><strong>Waiver of Subrogation</strong></p>
<p>Any insurance carried by the user or RUS is for the sole benefit of the respective party carrying that insurance. Both RUS and users wave their rights to make any kind of claim against the other for loss or damage in the event of casualty that is covered by that party’s respective insurance and will cause such respective insurance policies to be endorsed to then wave the right of its respective insurers. </p>
<p><strong>No Warranties</strong></p>
<p>RUS, its team members, suppliers, and subcontractors, do not make any representations or warranties regarding any and all content contained in or accessed through RUS Services and are not liable or responsible for the accuracy, legality, decency, or copyright compliance of material contained in or accessed through RUS Services. RUS, its team members, suppliers, and subcontractors do not make any representation or warranties concerning recommendations or suggestions of products or services offered or purchased through RUS Services. Products and services offered or purchased, regardless of whether or not following any such recommendations or suggestions, through RUS Services are provided “as is” and with no warranty from RUS or others, unless, regarding others only, they are provided explicitly in writing by a clearly designated third party regarding a specific product.&nbsp;</p>
<p>RUS Services and content provided are done so on an “as-is” basis from RUS, its team members, suppliers, and subcontractors, and are done so without warranties of any kind, either implied or explicit, including, without limitation, implied warranties of fitness for a specific purpose, merchantability, or that the use of such services will error-free or without interruption. These limitations may not apply depending upon the laws of your state of residence and location of requested Services. </p>
<p><strong>PAYMENT OF SERVICES&nbsp;</strong></p>
<p><strong>Terms, Rent, &amp; Other Fees</strong></p>
<p>This Agreement and the period for which it is in effect is called the “term”. When you sign or accept the Agreement the term begins and shall continue on a month-to-month basis until it is terminated. This Agreement can be modified at any time after providing notice to the user.&nbsp;</p>
<p>Rent shall be paid to RUS by the user according to the Services used. Rent for bin storage must be paid promptly when it is due, or users shall be subject to a Delinquency Fee, barred access, and in the end a lien sale. This rent must be paid without notice or encouragement from RUS, in full, and without any sort of deductions. The rent for bin storage and Services is not refundable. The amount of rent paid by the user is the sum of the full amounts detailed in the <a href="/pricing">Pricing Standards</a> that apply to your Services used, including rent coming from the Minimum Commitment, any additional fees for the Services, or any applicable sales or other taxes from the government or any taxing authority.&nbsp;</p>
<p>Any fees, charges, and rent may be adjusted by RUS. Should any rent adjustments occur, RUS will give notice to users at least (30) days in advance of the first day of effective rent adjustment pricing. Any adjustments to fees, charges, and rent will not otherwise impact the terms of this Agreement and all other terms will remain in full force and effect. </p>
<p><strong>Payments &amp; Defaults&nbsp;</strong></p>
<p>Users must pay with a valid, unexpired debit or credit card (“Card”). RUS will only accept debit and credit cards for both recurring and one-time charges. RUS does not accept prepaid cards nor do we accept cash, bank or certified checks for RUS services. Users agree that RUS or the company’s it uses for such transactions will save your Card information and will use it for recurring monthly payments and other future charges. Users Card will be automatically charged when rent is due and may also be charged automatically for outstanding charges. RUS is not responsible for overdraft fees or fees of any kind applied to the user by their bank or Card issuer that arise from RUS charges or attempted charges to your Card. If the user wishes to stop charges to their particular Card and change their form of payment, they must notify RUS or make the appropriate changes to their payment settings within their digital account. It is the user’s responsibility to provide a valid Card for payments and ensure rent is able to continue to be paid on that Card. If RUS fails to be able to bill the Card for user Services, we will alert the user and continue to attempt to make the charge until it succeeds.&nbsp;</p>
<p>You are required to make all rent payments and other payments when they are due. If you fail to pay rent or other fees on time, in addition to any other conditions, it means you are in “default” under this Agreement.</p>
<p>Users must make all payments for the Services when they are due. Failure to pay rent for bin storage or any other fees related to the Services makes the user in “default” under the Agreement. Should that occur, RUS may deny the user access to Your Items at its sole discretion. RUS will make every reasonable effort to notify the users of default and will continue to attempt any fees due related to the Services, though should the user remain in default they will be charged additional fees related to the Pricing Standards.&nbsp;</p>
<p>If the user is late on payment, RUS may agree to accept full or partial payment using the Card or another payment method agreed upon by all parties. However users will still not be allowed to access Your Items while in default, and RUS accepting a partial payment does not absolve default or release Your Items from default or stop RUS from pursuing payment or other remedies at law and outlined in this Agreement.&nbsp;</p>
<p>RUS will have a lien upon all personal property stored by the user for any and all outstanding charges related to its Services, now or in the future, and for any additional expenses that may occur and are necessary for the preservation of said property, or expenses that occur in the sale of or other disposition of the personal property, as permitted by law. RUS has the right to enforce the lien through the sale of personal property stored by the user at a lien sale in accordance with law. In such a case RUS will provide due and proper notice prior to the lien sale. The notice may be given by mail or email, whichever is required by the laws where Your Items are stored.&nbsp;</p>
<p>Users agree that should they pay by Card and later the charges related to Service are reversed or charged-back for any reason, including being unauthorized or not permitted, after thirty (30) days if a replacement Card has not been provided by the user and past charges repaid (including additional fees associated with the charge-back or reversal), RUS has the right to consider all Your Items in storage as surrendered and may sell said items according to the procedures of its abandoned items. In such a case RUS will credit the user account with the net proceeds and after the user will still remain liable to RUS for any outstanding amounts. </p>
<p><strong>ADDITIONAL POLICIES&nbsp;</strong></p>
<p><strong>Changes to RUS Use Policies</strong></p>
<p>RUS reserves the right to change these Terms, our Privacy Policy, our Pricing Standards, and our Rules of Storage at any time and with no notice if said changes govern new Services being offered by RUS, minimize your obligations to RUS, or if they expand RUS obligations. Beyond that, RUS may change, adjust, or modify these Terms, our Privacy Policy, our Pricing Standards, and our Rules of Storage at any time with notice to the user. If RUS does change, adjust, or modify these Terms, our Privacy Policy, our Pricing Standards, or our Rules of Storage, users may be provided notice either via mail, email, SMS, on our website or during sign-in to your online profile. It is the responsibility of the user to make sure their online profile is up to date and to assess any notice RUS provides. The continued use of RUS and its Services constitutes user acceptance of these changes.&nbsp;</p>
<p>Should any part of the Agreement be found to be invalid or unenforceable, that particular provision will be eliminated or limited to the extent necessary so that the remaining portion shall remain in full force and effect and otherwise be enforceable.&nbsp;</p>
<p>Parts of these Terms will continue to remain in effect even after a user terminates their account. All provisions of these Terms that could reasonably survive termination, based upon the nature of the term, shall survive, which includes but is not limited to, limitations of liability, warranty disclaimers, ownership provisions.</p>
<p><strong>No Waivers&nbsp;</strong></p>
<p>Any failure by RUS or the user under these Terms to exercise any rights included within shall not serve as the basis for a waiver of that right.&nbsp;</p>
<p><strong>Transfer</strong></p>
<p>The rights of the user under our policies and within this Agreement are not transferable, sublicensable, or assignable with the prior written consent of RUS. RUS reserves the right and may assign, delegate, or transfer obligations and rights without user consent under our Terms. </p>
<p><strong>Dispute Resolution</strong></p>
<p>These terms shall be governed by the State of Ohio and shall be construed under the laws of the state, in disregard to any other conflicts of laws provided that any sale or auction of Your Items will follow and be in compliance with the requirements of laws in the state where Your Items have been stored.&nbsp;</p>
<p>No lawsuit, arbitration, or other action may be sustained by the user or others against RUS with regards to Your Items unless a written claim has been made in a timely manner and as outlined in these terms unless the user has provided RUS with an acceptable opportunity to inspect and assess your items and unless such an arbitration, lawsuit, or other action is begun within nine (6) months after the user has learned or should have learned of the destruction and/or loss of damage.&nbsp;</p>
<p>In spite of and without being opposed by the previously stated obligation to arbitrate disputes, all parties shall have the right to pursue injunctive or any other equitable relief from any court. For purposes of these Terms, all parties consent to venue and exclusive jurisdiction in the state or federal courts located in Ohio. Arbitration will take place on an individual basis under these terms, and class actions and class arbitrations are not permitted. Users understand and agree that by entering into these Terms and consenting to this Agreement, they and RUS are waiving the right to participate in a class action or to trial by jury. </p>
<p><strong>Agreement in its Entirety&nbsp;</strong></p><p id="yui_3_17_2_1_1677115214891_447">This Agreement with RUS and any and all addenda or written amendments completed at the same time as this Agreement, and any additional notices provided by RUS under this Agreement, establish the entire Agreement for all parties with regards to the subject matter hereof and do supersede all prior Agreements, understandings, and representations, whether written or oral, with respect thereto. There are no warranties, agreements, or representations by or between all parties which are not fully established within this Agreement and no RUS representative or team member is authorized to make any warranties, Agreements or representations other than what was clearly established here, with the exception of the Privacy Policy, Pricing Standards, and Rules of Storage. All changes must be in writing and signed by all parties except as otherwise provided. </p>
<p></p>

</div>

        </Col>
      </Row>
    </>
  )
}
