import { useState } from 'react';
import { Button, Card, Dropdown, Image, Form, Col, Row, Modal } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import NumericInput from 'react-numeric-input';
import { loadStripe } from '@stripe/stripe-js';

import { functions } from '../firebase.js'
import { httpsCallable } from 'firebase/functions'

import bin from '../assets/bin.jpg'
import binb from '../assets/binb.jpg'
import tape from '../assets/tape.png'
import wrap from '../assets/wrap.png'

let stripePromise;

const createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession')
const checkZipService = httpsCallable(functions, 'checkZipService')

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_PUBLISHABLE_KEY);
  }

  return stripePromise;
};

export default function Cart() {
  const inventory = [{
    name: "Extra Large Storage Bins",
    description: "4.0 cubic feet and measure 25\" wide x 18\" long x 25\" tall",
    image: bin,
    price: process.env.REACT_APP_STRIPE_REDUP_BIN_XL_PRICE,
    pricings: [
        {start: 10, amount: 8},
        {start: 5, amount: 9},
        {start: 0, amount: 10}
    ]
  },{
    name: "Standard Storage Bins",
    description: "2.5 cubic feet and measure 27\" wide x 17\" long x 12\" tall",
    image: binb,
    price: process.env.REACT_APP_STRIPE_REDUP_BIN_STANDARD_PRICE,
    pricings: [
        {start: 10, amount: 5},
        {start: 5, amount: 6},
        {start: 0, amount: 7}
    ]
  },{
    name: "Duck Brand Small Bubble Cushioning Wrap",
    description: "Clear, 12 in x 200 ft",
    image: wrap,
    price: process.env.REACT_APP_STRIPE_REDUP_WRAP_PRICE,
    pricings: 23,
  },{
    name: "Scotch Sure Start Packing Tape",
    description: "Clear, 1.99 in x 25.7 yard Roll with Dispenser",
    image: tape,
    price: process.env.REACT_APP_STRIPE_REDUP_TAPE_PRICE,
    pricings: 4.50,
  }];

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [inventoryCart, setInventoryCart] = useState(inventory);
  const [zipCode, setZipCode] = useState("");
  const [zipIsLoading, setZipIsLoading] = useState(false);
  const [zipStatus, setZipStatus] = useState(false);
  const [zipModalShow, setZipModalShow] = useState(true);

  const navigate = useNavigate()

  const onZipCodeChange = async (newZip) => {
    if (zipIsLoading) {
      return // don't allow changing zip mid check
    }
    if (newZip.length > 0 && newZip.match(/^[0-9]+$/) == null) {
      return // only digits allowed
    }
    if (newZip.length > 5) {
      return // zip must be less than 5 digits
    }
    setZipCode(newZip)
    setZipStatus(false)
    if (newZip.length == 5) {
      setZipIsLoading(true)
      const res = await checkZipService(newZip)
      if (!res || !res.data) {
          console.log("zipStatus res:")
          console.log(res)
          console.log("error checking zip code, this breaks checkout and assumes all zipcodes are too far away...")
          setZipIsLoading(false)
          setZipStatus(false)
          return
      }
      console.log("zip data: ")
      console.log(res.data)
      if (res.data.zipStatus == true) {
        setZipStatus(true)
      } else {
        setZipStatus(false)
      }
      setZipIsLoading(false)
    }
  }

  const getItem = (price) => {
    return inventory.find(item => item.price === price)
  }

  const getItemName = (price) => {
    return getItem(price).name
  }

  const calculateItemPrice = (price, quantity) => {
    const pricings = getItem(price).pricings
    if (typeof pricings === 'number') {
      return (pricings * quantity)
    }
    return pricings.find(pricing => quantity >= pricing.start).amount * quantity
  }

  const calculateItemPriceStr = (price, quantity) => {
    const amount = calculateItemPrice(price, quantity)
    const pricings = getItem(price).pricings
    if (Array.isArray(pricings)) {
      return "$"+(amount/quantity)+" each, $"+amount+" total"
    }
    if (Number.isInteger(amount)) {
        return "$"+amount
    }
    return "$"+amount.toFixed(2)
  }

  const removeItemFromCart = (price) => {
    let newInventoryCart = []
    inventoryCart.map((item) => {
      if (item.price === price) {
          item.cart = null
      }
      newInventoryCart = [...newInventoryCart, item]
    })
    setInventoryCart(newInventoryCart)
  };

  const addItemToCart = (price, quantity) => {
    let newInventoryCart = []
    inventoryCart.map((item) => {
      if (item.price === price) {
        if (!item.cart) {
          item.cart = {price: price, quantity: quantity}
        } else {
          item.cart.quantity = item.quantity+1
        }
      }
      newInventoryCart = [...newInventoryCart, item]
    })
    setInventoryCart(newInventoryCart)
  };

  const setCartItemQuantity = (price, quantity) => {
    let newInventoryCart = []
    inventoryCart.map((item) => {
      if (item.price === price) {
        if (item.cart) {
          item.cart.quantity = quantity
        } else {
          item.cart = {price: price, quantity: quantity}
        }
      }
      newInventoryCart = [...newInventoryCart, item]
    })
    setInventoryCart(newInventoryCart)
  }

  const handleCheckout = async () => {
    let cartCopy = []
    inventoryCart.map((item) => {
        if (item.cart) {
            cartCopy = [...cartCopy, item.cart]
        }
    })
    if (cartCopy.length === 0) {
        console.log("tried to checkout but cart is empty...")
        return
    }

    setLoading(true)
    console.log("redirectToCheckout")

    const res = await createStripeCheckoutSession(cartCopy)
    console.log("createStripeCheckoutSession")
    if (!res || !res.data.sessionId) {
        setStripeError("got invalid checkout session id, backend call may have failed?")
        console.log("res:")
        console.log(res)
        setLoading(false)
        return
    }

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({sessionId: res.data.sessionId})
    console.log("Stripe checkout error", error)

    if (error) {
        setStripeError(error.message)
    }
    setLoading(false)
  };

  const handleReturn = () => navigate("/request");
  const handleContinue = () => setZipModalShow(false);

  // debug only, haults page load
  if (stripeError) alert("DEBUG ONLY - stripe error: "+stripeError);
  
  let cartCopy = []
  let cartTotalBinsCount = 0
  let cartTotalItemsCount = 0
  let cartTotalBinsAmount = 0
  let cartTotalItemsAmount = 0
  inventoryCart.map((item) => {
    if (item.cart) {
      if (Array.isArray(item.pricings)) {
        cartTotalBinsCount += item.cart.quantity
        cartTotalBinsAmount += calculateItemPrice(item.cart.price, item.cart.quantity)

      } else {
        cartTotalItemsCount += item.cart.quantity
        cartTotalItemsAmount += calculateItemPrice(item.cart.price, item.cart.quantity)
      }
      cartCopy = [...cartCopy, item.cart]
    }
  })
  let i = 0

  return (
    <>
      <Modal
        show={zipModalShow}
        onHide={handleReturn}
        backdrop="static"
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Check Service Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row><Col><p>Let's make sure we service your address.</p></Col></Row>
          <Row>
            <Col xs="4" md="6" lg="3"><Form.Control type="text" placeholder="zipcode" value={zipCode} onChange={e => onZipCodeChange(e.target.value)} /></Col>
            <Col>{zipIsLoading ? "..." : zipStatus ? "All set! We service your zipcode" : zipCode.length == 5 ? "Sorry, zipcode too far or invalid" : "Enter your zipcode"}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleContinue} disabled={!zipStatus}>Get Started</Button>
        </Modal.Footer>
      </Modal>
      <Row style={{marginTop: "48px"}}>
        <h1 class="text-center" style={{color: "#DB0E24"}}><b>Storage Bin Subscription</b></h1>
        <p class="text-center" style={{color: "#DB0E24"}}>*Monthly subscription minimum of 3 bins (total) for 6 months</p>
      </Row>
      {inventoryCart.map((c) => (
        <Row key={c.price}>
          <Col xs="12" lg="6">
            <h3 class="text-center" style={{color: "#DB0E24"}}><b>{c.name}</b></h3>
            <p class="text-center">{c.description}</p>
          </Col>
          <Col xs="12" lg="6"></Col>
          <Col xs="6" sm="3">
            <Image fluid src={c.image} />
          </Col>
          <Col xs="6" sm="3">
            {Array.isArray(c.pricings) && <table style={{display:"inline-block",textAlign:"center"}}>
            <tr><th colSpan="2" style={{color: "white", padding: "4px", backgroundColor: "#DB0E24"}}>SAVE THE MORE YOU ORDER!</th></tr>
            <tr style={{color: "#DB0E24"}}><th><strong><em>BINS</em></strong></th><th><strong><em>PRICE</em></strong></th></tr>
            {c.pricings.map((p) => (
                <tr>
                <td><b>{p.start <= 0 ? 1 : p.start+"+"}</b></td>
                <td><b>${p.amount}</b></td>
                </tr>
            ))}
            </table>}
            {!Array.isArray(c.pricings) && <p>${c.pricings.toFixed(2)} per roll</p>}
          </Col>
          <Col xs="12" sm="6">
            <Row>
              <Col xs="12" lg="6" xl="5">
                <p>
                  <span>
                    {c.cart && <NumericInput size={5} mobile min={1} max={25} value={c.cart.quantity} onChange={(v, s, i) => setCartItemQuantity(c.cart.price, v)}/>}
                    {!c.cart && <NumericInput size={5} mobile min={0} max={1} value={0} onChange={(v, s, i) => setCartItemQuantity(c.price, v)}/>}
                  </span> {Array.isArray(c.pricings) && <span>bins</span>}{!Array.isArray(c.pricings) && <span>rolls</span>}
                </p>
                <p>
                  {c.cart && calculateItemPriceStr(c.cart.price, c.cart.quantity)}
                </p>
              </Col>
              {(!c.cart && Array.isArray(c.pricings)) && <Col xs="12" lg="6" xl="7"><Button onClick={() => addItemToCart(c.price, 3)}>+ Add to Cart</Button></Col>}
              {(!c.cart && !Array.isArray(c.pricings)) && <Col xs="12" lg="6" xl="7"><Button onClick={() => addItemToCart(c.price, 1)}>+ Add to Cart</Button></Col>}
              {c.cart && <Col xs="12" lg="6" xl="7"><p><Button onClick={() => removeItemFromCart(c.cart.price)}>Remove</Button></p></Col>}
            </Row>
          </Col>
          <Col xs="12"><hr /></Col>
        </Row>
      ))}
      <p>*Minimum of 3 bins for 6 months.</p>
      <Row style={{marginTop: "48px", marginBottom: "48px"}}>
        <Col md={{ offset: 6, span: 6}}>
          <h1>Cart</h1>
          {cartCopy.length === 0 && <p><i>no bins added yet</i></p>}
          {(cartCopy.length > 0 && cartTotalBinsCount > 0) && <p>{cartTotalBinsCount} bins - ${cartTotalBinsAmount}/month</p>}
          {(cartCopy.length > 0 && cartTotalItemsCount > 0) && <p>{cartTotalItemsCount} items - ${(Number.isInteger(cartTotalItemsAmount) ? cartTotalItemsAmount : cartTotalItemsAmount.toFixed(2))}</p>}
          {(cartCopy.length > 0 && Number.isInteger(cartTotalBinsAmount + cartTotalItemsAmount)) && <p><i>${(cartTotalBinsAmount + cartTotalItemsAmount)} due today</i></p>}
          {(cartCopy.length > 0 && !Number.isInteger(cartTotalBinsAmount + cartTotalItemsAmount)) && <p><i>${(cartTotalBinsAmount + cartTotalItemsAmount).toFixed(2)} due today</i></p>}
          <Button
            variant="danger"
            style={{backgroundColor: "#DB0E24"},{marginTop: "12px"}}
            onClick={handleCheckout}
            disabled={stripeError || isLoading || !zipStatus || cartCopy.length === 0 || cartTotalBinsCount < 3}>
              {isLoading ? "..." : "Proceed to Checkout"}
          </Button>
          <hr />
        </Col>
      </Row>
    </>
  );
}
