import { Button, Card, Image, Accordion, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Faqs() {
  var itemKey = 0


  return (
    <>
      <Row style={{marginTop: "48px"}}>
        <Col lg={{span: 10, offset: 1}} xl={{span:8, offset: 2}}>
          <Accordion>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>What areas does RedUp operate in?</Accordion.Header>
              <Accordion.Body>
                RedUp is based in the greater Akron area and currently serves homes in Northeast Ohio. Fill out the New Bin Request or contact us to have a member of our team confirm if you are in our service area.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How soon will new bins be delivered?</Accordion.Header>
              <Accordion.Body>
                New bin deliveries are made within 24 hours of receiving request & payment.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How quickly can I get my bins back?</Accordion.Header>
              <Accordion.Body>
                RedUp Storage operates Monday through Friday from 9 a.m. to 5 p.m. Bin deliveries can be scheduled at your convenience any time during our operating hours. While same day delivery cannot be guaranteed if requested after 12 p.m, bins can be delivered within 24 hours.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How safe are the storage bins?</Accordion.Header>
              <Accordion.Body>
                RedUp bins are extremely durable and designed to protect items during storage and shipping. They are made of lightweight high density polyethylene and can handle a stacked weight of 700 lbs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How big are the storage containers?</Accordion.Header>
              <Accordion.Body>
                The storage containers used by RedUp are 4.0 cubic feet and measure 25.8 x 18.7 x 25”. Head to the New Bin page for further details and examples on how best to fit items for storage. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={itemKey++}>
              <Accordion.Header>How do I know what I have in what bin?</Accordion.Header>
              <Accordion.Body>
                When your new bins are delivered, you will be provided with a form that corresponds with your pre-numbered bins. You then can fill out the form with a description of what you placed in each bin. Once these bins are picked up and placed in storage, RedUp will send you a digital copy of your bin inventory. 
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  )
}
