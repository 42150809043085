import React, { useState, useEffect } from 'react'
import { Card, Button, Alert, Modal, Row, Col, Form } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase.js'
import { ref, onValue, update } from 'firebase/database'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faTruck, faHouse, faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { functions } from '../firebase.js'
import { httpsCallable } from 'firebase/functions'

import "react-datepicker/dist/react-datepicker.css";

const sendBinRequestUpdate = httpsCallable(functions, 'sendBinRequestUpdate')

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default function Admin() {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()
 
  const [binRequestShow, setBinRequestShow] = useState(false)
  const [binRequestUser, setBinRequestUser] = useState(null)
  const [binRequestKey, setBinRequestKey] = useState(null)
  const [binRequestDay, setBinRequestDay] = useState(null)
  const [binRequestTime, setBinRequestTime] = useState(null)

  const [users, setUsers] = useState([])

  useEffect(() => {
    // TODO: make an API call to request a schedule ID that the user can't write to
    return onValue(ref(db, `/users/`), (snapshot) => {
      if (!snapshot.exists()) {
        console.log("no data found, this shouldn't happen...'");
        return;
      }
      const data = snapshot.val()
      console.log("got users")
      console.log(data)
      console.log(Object.keys(data))
      setUsers(snapshot.val())
      //Object.keys(data).map((key) => {
      //    binRequest = data[key]
      //    binRequest.key = key
      //    setUserBinRequests((userBinRequests) => [...userBinRequests, binRequest])
      //})
    }, (error) => {
        alert("error in onvalue: "+JSON.stringify(error))
    });
  }, [currentUser])
  
  const startManageBinRequest = (uid, binRequestKey) => {
      setBinRequestDay(null);
      setBinRequestTime(null);
      setBinRequestUser(uid)
      setBinRequestKey(binRequestKey)
      setBinRequestShow(true)
  }

  const finishManageBinRequest = (status) => {
    var updates = {};
    if (status == "pending") {
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/status`] = status;
    }
    if (status == "scheduled") {
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/scheduled_date`] = binRequestDay.toISOString().split('T')[0];
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/scheduled_time`] = binRequestTime;
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/status`] = status;
    }
    if (status == "delivered") {
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/delivered_at`] = Date.now();
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/status`] = status;
    }
    if (status == "return scheduled") {
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/return_scheduled_date`] = binRequestDay.toISOString().split('T')[0];
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/return_scheduled_time`] = binRequestTime;
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/status`] = status;
    }
    if (status == "returned") {
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/returned_at`] = Date.now();
        updates[`/users/${binRequestUser}/bin_requests/${binRequestKey}/status`] = status;
    }

    let day = "";
    if (binRequestDay) {
        day = binRequestDay.toISOString().split('T')[0];
    }
    let time = "";
    if (binRequestTime) {
        time = binRequestTime;
    }
    update(ref(db), updates);
    setBinRequestShow(false);
    let safeAddress2 = ""
    if (users[binRequestUser].bin_requests[binRequestKey].address.line2) {
        safeAddress2 = ` ${users[binRequestUser].bin_requests[binRequestKey].address.line2}`
    }
    sendBinRequestUpdate({
      "user": binRequestUser,
      "status": status,
      "day": day,
      "time": time,
      "address": `${users[binRequestUser].bin_requests[binRequestKey].address.line1}${safeAddress2} ${users[binRequestUser].bin_requests[binRequestKey].address.city}, ${users[binRequestUser].bin_requests[binRequestKey].address.state} ${users[binRequestUser].bin_requests[binRequestKey].address.postal_code}`.trim(),
    })
  }

  const handleBinRequestReturn = () => setBinRequestShow(false)

  const isWeekday = (date) => {
    const d = new Date(date)
    const day = d.getDay();
    return day !== 0 && day !== 6;
  };
  
  const binScheduleTimeOptions = [
    { value: '9am - 12pm', label: '9am - 12pm' },
    { value: '12pm - 3pm', label: '12pm - 3pm' },
    { value: '3pm - 6pm', label: '3pm - 6pm' },
  ]

  return (
    <>
      {binRequestUser 
      &&
          <Modal
            show={binRequestShow}
            onHide={handleBinRequestReturn}
            backdrop="static"
            size="md"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Manage Bin Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>status: {users[binRequestUser].bin_requests[binRequestKey].status}</div>
              <div>customer: <a href={`https://dashboard.stripe.com/customers/${users[binRequestUser].bin_requests[binRequestKey].customer}`} target="_BLANK">{users[binRequestUser].bin_requests[binRequestKey].customer}</a></div>
              <div>
                {users[binRequestUser].bin_requests[binRequestKey].address.line1} {users[binRequestUser].bin_requests[binRequestKey].address.line2} {users[binRequestUser].bin_requests[binRequestKey].address.city}, {users[binRequestUser].bin_requests[binRequestKey].address.state} {users[binRequestUser].bin_requests[binRequestKey].address.postal_code}
              </div>
              {(users[binRequestUser].bin_requests[binRequestKey].status == "scheduled" ||
                users[binRequestUser].bin_requests[binRequestKey].status == "delivered" ||
                users[binRequestUser].bin_requests[binRequestKey].status == "return scheduled" ||
                users[binRequestUser].bin_requests[binRequestKey].status == "returned")
              &&
                <div>Scheduled for Delivery: {users[binRequestUser].bin_requests[binRequestKey].scheduled_date} - {users[binRequestUser].bin_requests[binRequestKey].scheduled_time}</div>
              }
              {(users[binRequestUser].bin_requests[binRequestKey].status == "delivered" ||
                users[binRequestUser].bin_requests[binRequestKey].status == "return scheduled" ||
                users[binRequestUser].bin_requests[binRequestKey].status == "returned")
              &&
                <div>Delivered at: {Date(users[binRequestUser].bin_requests[binRequestKey].delivered_at).toLocaleString()}</div>
              }
              {(users[binRequestUser].bin_requests[binRequestKey].status == "return scheduled" ||
                users[binRequestUser].bin_requests[binRequestKey].status == "returned")
              &&
                <div>Scheduled for Bin Return: {users[binRequestUser].bin_requests[binRequestKey].return_scheduled_date} - {users[binRequestUser].bin_requests[binRequestKey].return_scheduled_time}</div>
              }
              {(users[binRequestUser].bin_requests[binRequestKey].status == "returned")
              &&
                <div>Bins Returned at: {Date(users[binRequestUser].bin_requests[binRequestKey].returned_at).toLocaleString()}</div>
              }
              <hr />
              <div>
                <div>
                <DatePicker
                    selected={binRequestDay}
                    onChange={(date) => setBinRequestDay(date)}
                    includeDateIntervals={[
                    { start: addDays(new Date(), -1), end: addDays(new Date(), 60) }
                    ]}
                    filterDate={isWeekday}
                    inline
                />
                </div>
                <div>
                Select time window:
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="schedule-time"
                    options={binScheduleTimeOptions}
                    onChange={(choice) => setBinRequestTime(choice.value)}
                />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {users[binRequestUser].bin_requests[binRequestKey].status == "pending" && (<><Button onClick={() => finishManageBinRequest("scheduled")} disabled={!binRequestDay || !binRequestTime}>Schedule Delivery</Button></>)}
              {users[binRequestUser].bin_requests[binRequestKey].status == "scheduled" && (<><Button onClick={() => finishManageBinRequest("scheduled")} disabled={!binRequestDay || !binRequestTime}>Reschedule Delivery</Button><Button onClick={() => finishManageBinRequest("delivered")}>Mark Delivered</Button></>)}
              {users[binRequestUser].bin_requests[binRequestKey].status == "delivered" && (<><Button onClick={() => finishManageBinRequest("return scheduled")} disabled={!binRequestDay || !binRequestTime}>Schedule Return</Button></>)}
              {users[binRequestUser].bin_requests[binRequestKey].status == "return scheduled" && (<><Button onClick={() => finishManageBinRequest("return scheduled")} disabled={!binRequestDay || !binRequestTime}>Reschedule Return</Button><Button onClick={() => finishManageBinRequest("returned")}>Mark Returned</Button></>)}
            </Modal.Footer>
          </Modal>
      }
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Dashboard</h2>
          <h3>Bin Requests</h3>
          {Object.keys(users).map((uid) => Object.keys(users[uid].bin_requests)
            //.filter(reqId => users[uid].bin_requests[reqId].status != 'returned')
            .map(reqId => (
              <div key={reqId}>
                <div>
                  <FontAwesomeIcon icon={users[uid].bin_requests[reqId].status == "pending" ? faCircleExclamation : (users[uid].bin_requests[reqId].status == "scheduled" ? faTruck : (users[uid].bin_requests[reqId].status == "delivered" ? faHouse : (users[uid].bin_requests[reqId].status == "return scheduled" ? faTruck : faSquareCheck)))} style={{"margin":"2px 6px"}} />
                  <span>{users[uid].bin_requests[reqId].status} - {users[uid].bin_requests[reqId].address.line1} {users[uid].bin_requests[reqId].address.line2} {users[uid].bin_requests[reqId].address.city}, {users[uid].bin_requests[reqId].address.state} {users[uid].bin_requests[reqId].address.postal_code}</span>
                </div>
                
                {users[uid].bin_requests[reqId].status == "scheduled" && <div>Scheduled for Delivery: {users[uid].bin_requests[reqId].scheduled_date} - {users[uid].bin_requests[reqId].scheduled_time}</div>}
                {users[uid].bin_requests[reqId].status == "delivered" && <div>Delivered at: {Date(users[uid].bin_requests[reqId].delivered_at).toLocaleString()}</div>}
                {users[uid].bin_requests[reqId].status == "return scheduled" && <div>Scheduled for Bin Return: {users[uid].bin_requests[reqId].return_scheduled_date} - {users[uid].bin_requests[reqId].return_scheduled_time}</div>}
                {users[uid].bin_requests[reqId].status == "returned" && <div>Bins Returned at: {Date(users[uid].bin_requests[reqId].returned_at).toLocaleString()}</div>}

                <div>Customer: <a href={`https://dashboard.stripe.com/customers/${users[uid].bin_requests[reqId].customer}`} target="_BLANK">{users[uid].bin_requests[reqId].customer}</a></div>
                <div>Order #: {uid.substring(0, 5)}</div>
                {users[uid].bin_requests[reqId].status != "returned" && <Button onClick={() => startManageBinRequest(uid, reqId)}>Manage Delivery</Button>}
                <hr />
              </div>
            ))
          )}
        </Card.Body>
      </Card>
    </>
  );
}
//{binRequest.address.line1} {binRequest.address.line2 ? binRequest.address.line2 : ""}
              //{binRequest.city}, {binRequest.state} {binRequest.postal_code}
