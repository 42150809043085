import React, { useState, useEffect } from 'react'
import { useAuth, userIsAdmin } from './contexts/AuthContext'
import { Container, Alert } from 'react-bootstrap'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { Button, Nav, Navbar } from 'react-bootstrap';
import logo from './assets/redup.jpg'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'

function App() {
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
  const emailAddress = process.env.REACT_APP_EMAIL

  const { currentUser, logout } = useAuth()
  const [error, setError] = useState("")
  const [expanded, setExpanded] = useState(false)
  const [loadingLogout, setLoadingLogout] = useState(false)
  const navigate = useNavigate()


  async function handleLogout() {
      setError("")

      try {
          setLoadingLogout(true)
          await logout()
          setLoadingLogout(false)
          navigate("/login")
      } catch (e) {
          console.log(e)
          setError("failed to logout: ", e.code)
          setLoadingLogout(false)
      }
  }

  // public navbar
  var navBarCollapse = (
    <Navbar.Collapse>
        <Nav className="ms-auto">
          <LinkContainer to="/request"><Nav.Link onClick={() => setExpanded(false)}>Bin Sizes</Nav.Link></LinkContainer>
          <LinkContainer to="/return"><Nav.Link onClick={() => setExpanded(false)}>Bin Return</Nav.Link></LinkContainer>
          <LinkContainer to="/about"><Nav.Link onClick={() => setExpanded(false)}>About</Nav.Link></LinkContainer>
          <LinkContainer to="/faq"><Nav.Link onClick={() => setExpanded(false)}>FAQs</Nav.Link></LinkContainer>
          <LinkContainer to="/contact"><Nav.Link onClick={() => setExpanded(false)}>Contact</Nav.Link></LinkContainer>
          <LinkContainer to="/login"><Nav.Link onClick={() => setExpanded(false)}>Log In</Nav.Link></LinkContainer>
          <LinkContainer to="/cart" onClick={() => setExpanded(false)}><Nav.Link>
            <Button variant="danger"  style={{backgroundColor: "#DB0E24", marginTop: "-12px", padding: "12px 36px"}}>
              <b>Get Pricing</b>
            </Button>
          </Nav.Link></LinkContainer>
        </Nav>
      </Navbar.Collapse>
    )
  var brandLinkPath = "/"
  // logged in navbar
  if (currentUser) {
      if (userIsAdmin(currentUser)) {
        brandLinkPath = "/admin"
        navBarCollapse = (
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <LinkContainer to="/admin"><Nav.Link onClick={() => setExpanded(false)}>Admin Dashboard</Nav.Link></LinkContainer>
              <Nav.Link onClick={() => setExpanded(false)}>
                <Button variant="secondary" style={{marginTop: "-12px", padding: "12px 36px"}} onClick={handleLogout} disabled={loadingLogout} >
                  <b>Log Out</b>
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )
      } else {
        brandLinkPath = "/dashboard"
        navBarCollapse = (
          <Navbar.Collapse>
            <Nav className="ms-auto">
              <LinkContainer to="/dashboard"><Nav.Link onClick={() => setExpanded(false)}>Dashboard</Nav.Link></LinkContainer>
              <LinkContainer to="/account"><Nav.Link onClick={() => setExpanded(false)}>Account</Nav.Link></LinkContainer>
              <Nav.Link onClick={() => setExpanded(false)}>
                <Button variant="secondary" style={{marginTop: "-12px", padding: "12px 36px"}} onClick={handleLogout} disabled={loadingLogout} >
                  <b>Log Out</b>
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )
      }
  }
  return (
  <>
    <Container fluid>
      <Navbar  expand="md" expanded={expanded}>
        <LinkContainer to={brandLinkPath}>
          <Navbar.Brand onClick={() => setExpanded(false)} >
            <span>
              <img src={logo} height="100" alt="RedUp logo" />
            </span>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} />
        {navBarCollapse}
      </Navbar>
    </Container>
    <Container style={{marginTop: "24px"}}>
	    <Outlet />
  	</Container>
    <footer className="text-center redup-red-background" style={{marginTop: "24px", paddingTop: "64px"}}>
      <Link to="/about" style={{color: "white", margin: "12px"}}>About</Link>
      <Link to="/faq" style={{color: "white", margin: "12px"}}>FAQs</Link>
      <Link to="/contact" style={{color: "white", margin: "12px"}}>Contact</Link>
      <Link to="/terms" style={{color: "white", margin: "12px"}}>Terms of Service</Link>
      <p style={{marginTop: "24px"}}><a style={{color: "white"}} href={"mailto:"+emailAddress}>{emailAddress}</a><br /><a style={{color: "white"}} href={"tel:"+phoneNumber}>{phoneNumber}</a><br />Wadsworth, Ohio</p>
      <p style={{marginBottom: "0px"}}><a href="https://www.instagram.com/redupstorage/" target="_BLANK" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size="lg" style={{color: "white", margin: "6px 12px"}} /></a><a href="https://www.facebook.com/RedUpStorage" target="_BLANK" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} size="lg" style={{color: "white", margin: "6px 12px"}} /></a></p>
    </footer>
  </>
  )
}

export default App;
