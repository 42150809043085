import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import { isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth'
import { Outlet, Navigate } from 'react-router-dom'
import { functions } from '../firebase.js'
import { httpsCallable } from 'firebase/functions'

const checkUserExists = httpsCallable(functions, 'checkUserExists')
const sendSignInLink = httpsCallable(functions, 'sendSignInLink')

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function userIsAdmin(currentUser) {
    if (!currentUser || !currentUser.email) {
        return false
    }
    const emailDomain = currentUser.email.split('@')[1];
    if (emailDomain === 'redupstorage.com') {
        return true
    }
    return false
}

export function AdminRoutes(props) {
  const { currentUser } = useAuth()
  if (userIsAdmin(currentUser)) {
    return <Outlet />
  }
  return <Navigate to={props.to} replace />
}

export function AuthRoutes(props) {
  const { currentUser } = useAuth()

  return (
    currentUser ? <Outlet /> : <Navigate to={props.to} replace />
  )
}

export function PublicRoutes(props) {
  const { currentUser } = useAuth()

  return (
    !currentUser ? <Outlet /> : <Navigate to={props.to} replace />
  )
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  // TODO: triggerLogin and confirmLogin need to be wrapped in try/catch to handle errors
  async function triggerLogin(email) {
      const res = await checkUserExists(email)
      if (!res || !res.data) {
          return "An error occured: got empty response when checking user"
      }
      if (res.data.userExists) {
          await sendSignInLink({"email": email})
          return "ok"
      } else {
          console.log("user not exists status: "+res.data.status)
          return "No account with that email, please check spelling or purchase bins first."
      }
  }

  function isSignInLink() {
      return isSignInWithEmailLink(auth, window.location.href)
  }

  function confirmLogin(email) {
      return signInWithEmailLink(auth, email, window.location.href)
  }

  function logout() {
      return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    isSignInLink,
    triggerLogin,
    confirmLogin,
    logout
  }

  return (
    <AuthContext.Provider value={value}>
	  {!loading && children}
    </AuthContext.Provider>
  )
}