import { Button, Card, Image, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Contact() {
  const navigate = useNavigate()
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER
  const emailAddress = process.env.REACT_APP_EMAIL
  const emailHref = `mailto:${emailAddress}`
  const phoneHref = `tel:${phoneNumber}`

  return (
    <>
      <Row style={{marginTop: "48px"}}>
        <Col md={{span: 5, offset: 1}} lg={{span: 4, offset: 1}}>
          <h1>Have questions?</h1>
          <p>For more information or to request help with scheduling bin pick up and delivery, contact us! A member of our team will be happy to help you.</p>
        </Col>
        <Col md="5" lg={{span: 4, offset: 2}}>
          <h3>Email</h3>
          <p><a href={emailHref}>{emailAddress}</a></p>
          <h3>Phone</h3>
          <p><a href={phoneHref}>{phoneNumber}</a></p>
        </Col>
      </Row>
    </>
  )
}
