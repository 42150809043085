import { Button, Card, Image, Accordion, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'

export default function Pricing() {

  return (
    <>
      <Row style={{marginTop: "48px"}}>
        <Col lg={{span: 10, offset: 1}} xl={{span:8, offset: 2}}>

<div>

<h3><strong>Pricing Standards</strong></h3>
<p>The Pricing Standards outline and describe the standard pricing for new RUS users and is part of the Terms of Service. RUS reserves the right to alter Standard Pricing for new users and/or to offer promotional packages or pricing to any user at any time and in any manner.&nbsp;</p>
<p><strong>General Storage Pricing</strong></p>
<p>A user's storage pricing is based upon the volume of Your Items that the user wishes to store. This determines the number of bins they will need to request for storage of said items and will be determined by the user before bin delivery. The number of bins selected at that time will initiate the monthly storage fee. Should the user wish to increase the number of bins in storage, they may make an additional bin request, at which time the cost of those additional bins will be added to their monthly charge.&nbsp;</p>
<p>A user’s monthly “Billing Cycle” will start upon request of bin delivery. An example being that if bins were requested on the 11th of the month, the user’s next Billing Cycle would also be on the 11th of the following month. All following Billing Cycles would also fall on the 11th of the month, barring the occurrence that a month does not have the same date within that month, and in such a case the billing will be processed on the first day of that month. Users can view their billing information on their account with RUS online. RUS is not able to alter the date of the Billing Cycle or in any way change the date of charge once first initiated by the user.&nbsp;</p>
<p>The rent for storing Your Items must be paid on the first day of the Billing Cycle and is not refundable in any amount if the user chooses to cancel their Services during the course of a Billing Cycle. If a user requests more bins and therefore expands their Services, charges for such will be included on the first day of the next billing cycle.&nbsp;</p>
<p>The pricing of Services with RUS is based partially on the commitment of the user to store Your Items. Any storage and use of Services with RUS has a Minimum Commitment. When users first request bins and RUS Services, or later add to their bins stored and therefore their RUS Services, they are agreeing to pay that then current price and fulfill those commitments throughout the Minimum Commitment period.&nbsp;</p>
<p>Should the user choose to, for whatever reason, stop their requested Services with RUS before the end of their Minimum Commitment period, RUS may charge the user the remaining rent all at once and that point may occur upon the delivery of Your Items prior to the expiration of the Minimum Commitment once the user has indicated they no longer wish to use RUS Services.&nbsp;</p>
<p>Users are in no way or under any circumstance able to receive any sort of refund on the Minimum Commitment. Should the user have Your Items returned before the end of that Minimum Commitment period, users must still pay the remainder of that Minimum Commitment owed to RUS and rent will not decrease.&nbsp;</p>
<p><strong>Other General Service Pricing</strong></p>
<p>RUS will charge fees for pick-up and delivery services when the appointment is scheduled. All such Service Fees associated with requests made by the user will be charged to the user’s card when the request is made.&nbsp;</p>
<p>Pick-ups and deliveries that are within the users Local Service Zone are on a first-come, first served basis and RUS can not guarantee an opening for such Services on a specific day or time. RUS will take every reasonable action to meet the unique requests of users should there be no availability in the user’s Local Service Zone within the next two days. If such a unique request is needed by the user they must contact RUS as soon as they are aware of such a need. These requests may cause an additional charge to be applied to the user, though such charges would be established prior to the pick-up or delivery. In such circumstances RUS may use third party contractors to assist in the completion of an expedited pick-up or delivery. RUS is not liable for any failings of that third party contractor and users agree to not hold RUS liable for any actions that occur through the use of the third party.&nbsp;</p>
<p>RUS is not a moving company and using the Services in such a way is prohibited unless RUS has agreed to additional terms with the user prior to such an event. Should the user move to a new home or address during the normal course of using RUS Services, users may update their address in their account so the correct location of future pick-ups and deliveries is on file. Though should a user request the delivery of a large amount of items to a new address that were recently stored, RUS reserves the right to contact the user and ask questions on the nature of the location change and storage before any deliveries are made. If the user fails to be honest and transparent in such a case, or makes clear they violated the policies outlined here regarding moving, RUS may apply the cost of delivery and any additional costs that may occur to the user. Users agree that if RUS does not agree, based upon its sole discretion, to deliver Your Items to a new address that is not the same address from where the items were picked up, the user must schedule a delivery of those said items to the original address.&nbsp;</p>
<p>Bin pick-ups and deliveries are costly services. If the user cancels at the last minute, reschedules at the last minute, or fails to be present for the selected pick-up or delivery time, that time is no longer available to another user. RUS must be provided advance notice of any kind of schedule change for requested Services. If the user fails to provide 24 hour notice, RUS will charge a cancellation fee to the user's account. Should the user also fail to be present for a requested Service, RUS will deem it as a canceled Service and will charge the user a cancellation fee.&nbsp;&nbsp;</p>
<p>RUS requires users to use their storage bins to ensure the safety and security of Your Items. Should users wish to use these bins in their home, they may purchase these bins from us for a fee. RUS will charge this fee, based upon the number of bins they would like to buy, to the user’s account. If users choose to keep the bins upon bin delivery, or have failed to allow RUS team members to come back and pick the bins up, users will be charged the bin fee for the amount of bins they have kept in their possession and not returned to RUS. In such a case users will be given notice of a failure to schedule bin pick-up, and when the charge will be applied. Any damage to bins while in the possession of the users may also result in, at the sole discretion of RUS, a bin fee should that bin no longer be usable.&nbsp;</p>
<p>Users may also purchase packing supplies through RUS. They may do so online when requesting a new bin delivery, or by contacting an RUS team member. Pricing will be made available online or when requested. RUS does not guarantee the availability of said packing supplies for purchase and use by RUS users.&nbsp;</p>
<p>As stated in the Terms, RUS may require users to purchase supplies from RUS should it be found that Your Items were not properly and securely packed. Such a cost of packing supplies would be given to the user and then charged on their account.&nbsp;&nbsp;</p>
<p>Should a user no longer want or need Your Items in storage with RUS, it is possible they can make a request for RUS to dispose of Your Items. This is on a case by case basis and the user and RUS must agree to any additional disposal fees prior as well as come to a written and signed agreement. If such an agreement is made, disposal occurs, and the user no longer requires as many bins in storage, your monthly storage rent will decrease so long as the Minimum Commitment was met.&nbsp;</p>
<p>If a user’s account defaults and there are additional charges that occur as a result, a Delinquency Fee will be applied to every following invoice until the user has paid in full and their account is no longer in default.&nbsp;</p>
<p>Should RUS incur any additional expenses related to such a default occurring, including but not limited to conducting a lien sale, collecting your debt, or preserving or disposing of Your Items, RUS will assess and apply, at our sole discretion, additional fees towards your account.&nbsp;</p>
<p>Should any of the RUS Services be subject to a sales tax, whether based upon the item or area of operation, said sales tax will be added to the user’s account. If a user has questions regarding such a sales tax, they may contact a RUS team member.&nbsp;</p>


</div>

        </Col>
      </Row>
    </>
  )
}
